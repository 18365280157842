<template>
    <div class="logged-user-profile page column">
      
        <HeaderComponent />
        <PageTitle pageTitle="mon profil" />
        <main class="main flex5 column between small-responsive-width">
            <section>
                <div>
                    <p class="bold need-mB first-uppercase row row_center">
                        <span  class="icon icon-user-rounded "></span>
                        Prénom
                    </p>
                    <p class="main-color-lighten need-mB first-uppercase">{{currentUser.prenom}}</p>
                </div>
                <div class="separation--large need-mB"></div>
                <div>
                    <p class="bold need-mB first-uppercase row row_center">
                        <span  class="icon icon-user-rounded "></span>
                        Nom
                    </p>
                    <p class="main-color-lighten need-mB first-uppercase">{{currentUser.nom}}</p>
                </div>
                <div class="separation--large need-mB"></div>
                <!--div>
                    <p class="bold need-mB first-uppercase row row_center">
                        <span  class="icon icon-info-rounded "></span>
                        Métier
                    </p>
                    <p class="main-color-lighten need-mB first-uppercase">Métier</p>
                </div>
                <div class="separation--large need-mB"></div-->
                <div>
                    <p class="bold need-mB first-uppercase row row_center">
                        <span  class="icon icon-aroba"></span>
                        Mail
                    </p>
                    <p class="main-color-lighten need-mB first-uppercase">{{currentUser.email}}</p>
                </div>
            </section>

            <router-link to="/logout" class="btn btn-short bg-white need-mT"><span class="zindex">Se déconnecter</span></router-link>
        </main>
        
    </div> 
</template>


<script>
import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'

export default {
    name: 'LoggedUserProfile',
    components: {
        HeaderComponent,
        PageTitle,
    },
    data(){
        return{
            //visibleLogoutComponent : false,
        }
    },
    computed:{
        currentUser(){
        //Je récupère les infos de la personne connectée :
        return this.$store.state.auth.user.login
        }
    }
}
</script>