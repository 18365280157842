import { createStore } from 'vuex'

import {auth} from './auth.module';
import {changes} from './changes.module';
import {navigation} from './navigation.module';

const store = createStore({
    modules: {
        auth,
        changes,
        navigation
    }
  })
export default store;