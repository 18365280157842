import TransmissionResultatsEmpty from './transmissionResultatsEmpty'
export default class Draft {

  constructor(user, modifications){
    this.draft = new TransmissionResultatsEmpty().enveloppe
    this.raceData = modifications.targetRace
    this.raceInfos = modifications.raceInfos
    this.raceResults = modifications.raceResults
    this.user = user
  }

 /** 
  *  --------------
  *  GENERATE DRAFT
  *  --------------
  */

  addAppVersion(){
    this.draft.Fichier.Kernel.Major = 0
    this.draft.Fichier.Kernel.Minor = 7
  }

  addDocumentInfos(user){
    const ctx = this.draft.Fichier

    ctx.Date = new Date()
    ctx.User = {
      NoLicence: user.noLicence,
      Nom: user.nom,
      Prenom: user.prenom
    }
  }

  addRace(race){
    const ctx = this.draft.Competition

    ctx.CoId = race.competitionId
    ctx.Epreuves = [{
      EpId: race.epreuveId,
      Nom: race.nom,
      DateDebut: race.dateDebut,
      DateFin: race.dateFin,
      Structure: race.stCode,
      CodeBateau: race.codeBateau,
      Grade: race.grade,
      VerifLink: race.urlVerif,

      Organisateurs: [],

      Resultats_Groupes: [{
        // utilisés peut-être plus tard (Julien)
        // Code: "",
        // Libelle: "",
        CodeBateau: race.codeBateau,
        DateCalcul: new Date(),
        NbClasses: race.nbCoureurs,
        NbManches_valides: race.nbManches,
        NbNations: race.nbNations,

        Manches: [],

        Bateaux: []
      }]
    }]
  }

  updateRace(data){
    if(data){
      this.draft.Competition.Epreuves[0].VerifLink = data.VerifLink
  
      const ctx = this.draft.Competition.Epreuves[0].Resultats_Groupes[0]    
      ctx.NbClasses = data.NbClasses
      ctx.NbManches_valides = data.NbManches_valides
      ctx.NbNations = data.NbNations
    }
  }

  addMembers(members){
    if (members) {

      const allShips = []

      // création des bateaux
      const rankShips = [...new Set(members.map(m => m.Rang))].filter((s) => s)
      rankShips.forEach((s) => {
        allShips.push({
          Rang: s.toString(),
          Temps: "",
          Equipage: [],
          Manches: []
        })
      })
      
      const timeShips = [...new Set(members.map(m => m.Temps))].filter((s) => s)
      timeShips.forEach((s) => {
        allShips.push({
          Rang: "",
          Temps: s.toString(),
          Equipage: [],
          Manches: []
        })
      })


      // ajout de l'équipage
      members.forEach((m) => {
        const ship = allShips.filter((s) => {
          if (!s.Rang) {
            return m.Temps == s.Temps
          }
          else if (!s.Temps){
            return m.Rang == s.Rang
          }
        })[0]

        ship.Equipage.push({
          Sexe: m.sexe || "",
          Club: m.club || "",
          NoLicence: m.noLicence,
          Nom: m.nom,
          Prenom: m.prenom
        })
      })
      
      // ajout au draft
      this.draft.Competition.Epreuves[0].Resultats_Groupes[0].Bateaux = allShips
    }
  }

  create(){
    this.addAppVersion()
    this.addDocumentInfos(this.user)
    this.addRace(this.raceData)
    this.updateRace(this.raceInfos)
    this.addMembers(this.raceResults)
    
    return this.draft
  }
}
