<template>
    <div class="race-summary-and-modification page column">
      
        <HeaderComponent />
        <PageTitle pageTitle="Résumé des informations" />

        <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                    <!-- FIL D'ARIANNE  -->

        <nav class="breadcrump">
            <ul class="breadcrump--container text-center">
                <li  class="item" title="Retourner à cette étape">
                    <router-link to="/results">Recherche d'épreuve</router-link>
                </li>
                <li class="item" title="Retourner à cette étape">
                    <router-link to="/results">Résultats de la recherche</router-link>
                </li>
                <li  class="item" title="Retourner à cette étape">
                    <router-link to="/checkout">Épreuve choisie : "{{ race.nom }}"</router-link>
                </li>
                <li class="current-item item" title="Vous êtes ici">
                    <span>Modification des infos de l'épreuve</span>
                </li>
            </ul>
        </nav>

        <main class="main flex1 large-responsive-width column">
            <p class="text-info">Vous pouvez ajouter ou modifier certaines informations</p>
            <p class="text-info need-mT">Les nombres de coureurs et de courses sont à renseigner obligatoirement si vous souhaitez assigner des licenciés à l'épreuve.</p>
            <p class="text-info need-mB2">Le nombre de nations et le lien de vérification sont optionnels.</p>
            <div class="responsive-wrap--flex">


                <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                        <!-- RECAP' INFOS COURSE  -->

                <section class="race-card card need-mTB">
                    <div class="row between row_center">
                        <h2 class="title2">{{ race.nom }}</h2>
                        <img 
                            v-show="race.resultatsTransmis" 
                            src="@/assets/trophy.svg" 
                            class="img"
                        />                    
                    </div>
                    <div class="bg-lightGrey inside-card need-mTB">
                        <p v-show="race.organisateur">
                            <span class="icon icon-star"></span>
                            Organisé par : {{ race.organisateur }}
                        </p>
                        <p v-show="race.lieu">
                            <span class="icon icon-location"></span>
                            Lieu : {{ race.lieu }}
                        </p>
                        <p v-show="race.hnLieu">
                            <span class="icon icon-diamond-rounded"></span>
                            {{ race.hnLieu }}
                        </p>
                        <p v-show="race.dateDebut && race.dateFin">
                            <span class="icon icon-calendar"></span>
                            Début : {{ toDayAndMonth(race.dateDebut,'DD/MM/YYYY')}} • Fin : {{ toDayAndMonth(race.dateFin,'DD/MM/YYYY') }}
                        </p>
                        <p>
                            <span class="icon icon-diploma"></span>
                            Code de l'épreuve : {{ race.epreuveId }}
                        </p>
                        <p v-show="race.hnNiveau">
                            <span class="icon icon-level"></span>
                            Niveau : {{ race.hnNiveau }}
                        </p>
                        <p v-show="race.support">
                            <span class="icon icon-boat"></span>
                            {{ race.support }}
                        </p>
                        <p class="bold main-color-lighten">
                            <span class="icon icon-user"></span>
                            Nombre de coureurs :<span class="need-mL5" v-if="race.nbCoureurs">{{ race.nbCoureurs }}</span><span class="need-mL5" v-else>A renseigner</span>
                        </p>
                        <p class="bold main-color-lighten">
                            <span class="icon icon-itinerary"></span>
                            Nombre de courses :<span class="need-mL5" v-if="race.nbManches">{{ race.nbManches }}</span><span class="need-mL5" v-else>A renseigner</span>
                        </p>
                        <p>
                            <span class="icon icon-flag"></span>
                            Nombre de nations : <span class="need-mL5" v-if="race.nbNations">{{ race.nbNations }}</span><span class="need-mL5" v-else>A renseigner</span>
                        </p>
                        <p class="own-style">
                            <span class="icon icon-link"></span>
                            Lien de vérif : <a :href="race.urlVerif" target="_blank" class="need-mL5 link--hover-underline" v-if="race.urlVerif">{{ race.urlVerif }}</a><span class="need-mL5" v-else>A renseigner</span>
                        </p>
                        <p v-show=" race.urlEpreuve" class="own-style">
                            <span class="icon icon-web"></span>
                            Site : <a :href="race.urlEpreuve" target="_blank" class="need-mL5 link--hover-underline">{{ race.urlEpreuve }}</a>
                        </p>
                    </div>
                </section>


                <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                            <!-- EDIT MODE -->


                <section id="race-modification-card" class="race-modification-card card bg-mainColorLighten need-mTB" :class="{ 'edition-mode' : isEditionMode }">
                    <div class="row between">
                        <h2 class="title2">Modification de l'épreuve</h2>
                    </div>
                    <div class="bg-lightGrey inside-card need-mTB">
                        <div class="row row_center">
                            <span class="icon icon-user"></span>
                            <div class="input--container required">
                                <span class="typing--text">Coureurs</span>
                                <input type="number"
                                    placeholder="Nombre de coureurs"
                                    step="1"
                                    min="0" 
                                    required
                                    oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null && validity.valid||(value='')"
                                    v-model="newData.runnersNumberForm"
                                >
                            </div>
                        </div>
                        <div class="row row_center need-mT">
                            <span class="icon icon-itinerary"></span>
                            <div class="input--container required">
                                <span class="typing--text">Courses</span>
                                <input type="number"
                                    placeholder="Nombre de courses"
                                    step="1"
                                    min="0" 
                                    required
                                    oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null && validity.valid||(value='')"
                                    v-model="newData.racesNumberForm"
                                >
                            </div>                        
                        </div>
                        <div class="row row_center need-mT">
                            <span class="icon icon-flag"></span>
                            <div class="input--container">
                                <span class="typing--text">Nations</span>
                                <input type="number" 
                                    step="1"
                                    min="0" 
                                    oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null && validity.valid||(value='')"
                                    placeholder="Nombre de Nations"
                                    v-model="newData.nationsNumberForm"
                                >
                            </div>   
                        </div>
                        <div class="separation--large need-mTB"></div>
                        <div class="input--container">
                            <span class="icon icon-link"></span>
                            <input 
                                type="text" 
                                placeholder="Lien de vérification"
                                v-model="newData.verifLinkForm"
                            >
                        </div>
                    </div>

                    
                </section>
            </div>

            <RedPopUp v-if="redPopUp.show" :textPopup="redPopUp.text" @closePopUp="redPopUp.show = false"/>
        </main>

        <section class="pagination">
            <router-link to="/checkout" class="btn need-mT"><span class="zindex">Annuler</span></router-link>
            <button @click="goToValidationPage()" class="btn btn-large bg-white_mainColorLighten need-mT">
                <span class="zindex">Valider</span>
            </button>
        </section>
       
    </div> 
</template>


<script>
import { mapGetters } from 'vuex'

import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'
import RedPopUp from '@/components/popups/RedPopUp.vue'

export default {
    name: 'RaceSummaryAndModification',

    components: {
        HeaderComponent,
        PageTitle,
        RedPopUp,
    },

    data(){
        return{
            race: {},

            // Style
            isEditionMode : true,
            btnPaginationText : 'Modifier',

            // Popups
            redPopUp: {
                show: false,
                text: ''
            },

            // Données
            raceResultsRegistered: [],

            newData: {
                racesNumberForm: '',
                runnersNumberForm: '',
                nationsNumberForm: '',
                verifLinkForm: '',
            },

            el: document
        }
    },

    computed: {
        ...mapGetters({
            targetRace: 'changes/getTargetRace',
            changes: 'changes/getChanges'
        }),

        toDayAndMonth() {
            return (value, formatDate)=>{
                return this.$dayjs(value).format(formatDate)
            }
        },
    },

    beforeMount() {
        if (!this.targetRace) this.$router.push('/')
        this.setInfos()
        this.checkAlreadyEdited()
    },

    methods: {

       /**
        *   --------------
        *   POP UP METHODS
        *   --------------
        */

        showRedPopUp(message){
            this.redPopUp.text = message;
            this.redPopUp.show = true;
        },

    // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

       /**
        *   --------------
        *     DATA SETUP
        *   --------------
        */

       setInfos(){
           this.race = this.targetRace
       },

       checkAlreadyEdited(){
           if(this.changes.raceInfos){
               this.race = {
                    ...this.race,
                    nbCoureurs: this.changes.raceInfos.NbClasses,
                    nbManches: this.changes.raceInfos.NbManches_valides,
                    nbNations: this.changes.raceInfos.NbNations,
                    urlVerif: this.changes.raceInfos.VerifLink
                }
           }
       },



    // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

       /**
        *   --------------------------
        *   VERIFICATION DU FORMULAIRE
        *   --------------------------
        */

        goToValidationPage(){
            if ((!this.race.nbCoureurs && this.newData.runnersNumberForm == "") ||
                (!this.race.nbManches && this.newData.racesNumberForm == "")){
                    this.showRedPopUp('Veuillez renseigner le nombre de coureurs et le nombre de courses')
            }
            else {
                this.formValidated()
            }
        },

    // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

       /**
        *   -------------------
        *   GESTION DES DONNÉES
        *   -------------------
        */

        formValidated(){

            /** 
             * BONUS : 
             * 
             * le casse-tête de la modif du nb de nations,
             * FFVOILE nous envoie un nb de nations de 1 par défaut...
             * 
             * Il faut donc détecter si c'est la première fois qu'on modifie la course
             * pour lui renvoyer une chaîne vide s'il n'est pas renseigné (Julien)
             */

            let nbNat

            // CAS N°1: PREMIER RENSEIGNEMENT DES INFOS DE LA COURSE
            if(!this.race.nbCoureurs && !this.race.nbManches){

                // 1) si le nb de nations n'est pas renseigné
                if(this.newData.nationsNumberForm === ""){
                    nbNat = null
                }
                // 2) si le nb de nations est renseigné
                else{
                    nbNat = this.newData.nationsNumberForm
                }
            }

            // CAS N°2: MODIFICATIONS ULTERIEURES DES INFOS DE LA COURSE
            else {
                // 1) si modification du nb de nations
                if(this.newData.nationsNumberForm !== ""){
                    nbNat = this.newData.nationsNumberForm
                }
                // 2) si modification de la course, mais pas du nb de nations
                else {
                    nbNat = this.race.nbNations
                }
            }

            const data = {
                targetRace: this.race,
                racesNumber: this.newData.racesNumberForm || this.race.nbManches,
                runnersNumber: this.newData.runnersNumberForm || this.race.nbCoureurs,
                nationsNumber: nbNat,
                verifLink: this.newData.verifLinkForm || this.race.urlVerif
            }

            this.$store.dispatch('changes/updateRaceInfos', data)
            this.$router.push('/checkout')
        }
    }
}
</script>