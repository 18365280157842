<template>
    <div class="useful-links page column">
      
        <HeaderComponent />
        <PageTitle pageTitle="liens utiles" />
        <main class="main flex1 column small-responsive-width">
            <section class="small-card row between">
                <div>
                    <p class="bold need-mB5">Feedback</p>
                    <p>Merci de nous donner vos impressions</p>
                </div>
                <a href="https://forms.gle/i6rXwKoB1qX9ihPn9" target="_blank"><span class="icon icon-eye-square"></span></a>
            </section>
        </main>
        <section class="pagination">
            <router-link to='/' class="btn"><span class="zindex">Retour</span></router-link>
        </section>
    </div> 
</template>


<script>
import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'

export default {
    name: 'FeedbacksView',
    components: {
        HeaderComponent,
        PageTitle,
    },
}
</script>