<template>
    <section class="popup--container">
        <div class="bg-blur"></div>
        <div class="popup bg-yellow">
            <span class="icon icon-exclamation-rounded"></span>
            <p class="need-mTB2 text-center">{{textPopup}}</p>
            <div class="row row_center">
                <button class="popup-btn" @click="action1">{{txtBtn1}}</button>
                <button class="popup-btn" @click="action2">{{txtBtn2}}</button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'YellowPopUp',
  props: {
    textPopup: String,

    txtBtn1: String,
    action1: { type: Function },

    txtBtn2: String,
    action2: { type: Function },

  }
}
</script>