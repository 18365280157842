<template>
    <section class="page-title--container bg-mainColor">
        <h1 class="title1">{{pageTitle}}</h1>
    </section>
</template>


<script>
export default {
  name: 'PageTitle',
  props: {
    pageTitle: String,
  }
}
</script>