<template>
    <div class="race-history page column">
      
        <HeaderComponent />
        <PageTitle pageTitle="Historique des épreuves traitées" />
        <loading-spinner-vue v-if="loading"></loading-spinner-vue>
        <main class="main flex1 column large-responsive-width" v-if="!loading">
            <p class="text-info" v-show="dataList.length>0">Choisissez une épreuve pour plus d'options</p>
            <p class="text-info" v-show="dataList.length<1">Vous n'avez pas encore envoyé de modifications</p>
            <div class="responsive-wrap--grid" :class="{'onlychild': dataList.length === 1}">
               <section class="race-card card need-mTB" v-for="race of dataList" :key="race.id">
                    <div class="row between row_center">
                        <h2 class="title2"> {{ race.name }}</h2>
                        <img src="@/assets/trophy.svg" class="img"/>                    
                    </div>
                    <div class="bg-lightGrey inside-card need-mTB">

                        <p>
                            <span class="icon icon-diploma"></span>
                            N° de l'épreuve : {{ race.epreuveId }}
                        </p>
                       
                    </div>
                    <button @click="saveRaceandSwitchPage(race.epreuveId)" class="btn btn-large bg-mainColorLighten need-mT"><span class="zindex">Choisir cette épreuve</span></button>
                </section>
            </div> 
        </main>
        <section class="pagination">
            <button @click="$router.back()" class="btn"><span class="zindex">Retour</span></button>
        </section>
        
    </div> 
</template>


<script>
import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'
import apiService from '@/services/api.service'
import { mapGetters } from 'vuex'
import LoadingSpinnerVue from '@/components/elements/LoadingSpinner.vue'

export default {
    name: 'RaceHistory',
    components: {
        HeaderComponent,
        PageTitle,
        LoadingSpinnerVue
    },
    data(){
        return{
            dataList: [],
            loading:true
        }
    },

    computed: {
        ...mapGetters({ 
            user: 'auth/currentUser'
        })
    },

    created(){
        this.getData()
    },

    methods: {
        async getData(){
            const res2 = await apiService.getUserHistory(this.user.login.noLicence)
            this.dataList = res2.data
            this.loading = false;
        },

        async saveRaceandSwitchPage(raceId){

            var dataToSend = {
                codeEpreuve: raceId.toString()
            };

            var resultats = await apiService.raceSearch(dataToSend);
            if (resultats.data.length > 0) {
                var raceObj = resultats.data[0]
            }
            console.log(raceObj)

            if(this.hasEdit){
                this.showYellowPopup = true
            }
            else {
                this.$store.dispatch('changes/reset')
                this.$store.dispatch('changes/setRace', raceObj)

                const response = await apiService.getRaceResults(raceObj.epreuveId)
                if(response){
                    this.$store.dispatch('changes/setRaceResultsFromApi', response)
                    this.$router.push('/checkout')
                }
                else {
                    this.showRedPopup = true
                }
            }
        },
    }
    
}
</script>