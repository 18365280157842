class StorageService {
  storageKey = `${window.VUE_STORE_PREFIX}-sdr-ffvoile`
  
    get() {
      const user = JSON.parse(localStorage.getItem(this.storageKey));
      return user;
    }
  
    updateToken(token) {
      let user = JSON.parse(localStorage.getItem(this.storageKey));
      user.token = token;
      localStorage.setItem(this.storageKey, JSON.stringify(user));
    }
    update(user) {
      localStorage.setItem(this.storageKey, JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem(this.storageKey);
    }
    

  }
  
  export default new StorageService();