<template>
    <div class="login page column center">
      
        <header class="header column row_center">
            <img class="logo" src="@/assets/logo.png" alt="logo de la Fédération Française de Voile"/>
            <div class="separation--short"></div>
            <h1 class="title3">Transmission des résultats</h1>
        </header>
        <main class="main column flex-end small-responsive-width">
            <div v-show="!loading">
                <label for="username" class="need-mL"> Licence </label>
                <div class="input--container">
                    <span class="icon icon-user"></span>
                    <input class="input input-style-inline" 
                            type="text"
                            id="username"
                            v-model="username" 
                            placeholder="Identifiant"
                            min="8"
                            max="32"
                            name="username"
                            autocomplete="username" 
                    />
                </div>
                <label for="password" class="need-mL"> Mot de passe </label>
                <div class="input--container">
                    <span class="icon icon-lock"></span>
                    <input class="input-password input-style-inline" 
                            type="password"
                            v-model="password"
                            id="password"
                            placeholder="Mot de passe"
                            min="8"
                            max="32"
                            name="password"
                            autocomplete="current-password"
                    />
                    <span class="icon-switch icon-eye-crossed" @click="showPassword($event)"></span>
                </div>
            </div>
            <LoadingSpinner v-show="loading" />

            <p v-if="message" role="alert" class="alert alert-danger">{{message}}</p>
            <button v-if="!loading" class="btn btn-short bg-mainColor" type="submit" @click="handleLogin()"><span class="zindex">Connexion</span></button>
        
        </main>
    </div>
</template>


<script>
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue'
import { mapGetters } from 'vuex';

export default {
  name: 'LoginView',
  components: {
    LoadingSpinner
  },

  data() {
    return {
        username: null,
        password: null,
        loading: false,
        message: ''
    };
  },
  computed: {
     ...mapGetters({
      isLoggedIn: 'auth/isLogged',
    }),
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push('/');
    }
    // console.log("Using API Endpoint ",window.VUE_APP_WEBAPI)
  },
  methods: {
        showPassword: function(event) {
            if (event.target.previousSibling.type === "password") {
                event.target.previousSibling.type = "text";
                event.target.classList.replace('icon-eye-crossed', 'icon-eye');
            } else {
                event.target.previousSibling.type = "password";
                event.target.classList.replace('icon-eye', 'icon-eye-crossed');
            }
        },
        //copié de T&P :
       async handleLogin() {
            if (!this.username || !this.password){
                this.message = "Tous les champs sont obligatoires pour vous connecter"

            }else if ((this.username && this.password )) {
                this.loading = true;
                this.message = "";
                var user = {
                    username: this.username,
                    password:this.password
                }
                try {
                    await this.$store.dispatch('auth/login', user)
                    this.$router.push('/');
                } catch (error) {
                    this.loading = false;
                    this.message = "Votre nom d'utilisateur ou votre mot de passe n'est pas valide"
                }
                
            }
        }
    }
}
</script>