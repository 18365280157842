import axios from "axios";

const instance = axios.create({
  baseURL: window.VUE_APP_WEBAPI,
  headers: {
    "Content-Type": "application/json",
  }
  ,withCredentials : true,
  
});

export default instance;