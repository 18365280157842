<template>
  <div class="relative" id="app--content">
      <router-view/> 
  </div>
</template>

<script>

import EventBus from "./common/EventBus";
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs'

export default {
  name: 'App',
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    enableDatadog(version) {
      datadogRum.init({
          applicationId: '6eb91e6a-4d32-4b1e-816f-d8b156d4dff1',
          clientToken: 'pub96d4c90276cbce94ad99ed0b090aee83',
          site: 'datadoghq.eu',
          service:'ffvoile-sdr',
          
          version: version,
          sampleRate: 100,
          premiumSampleRate: 100,
          trackInteractions: true,
          defaultPrivacyLevel:'mask-user-input',
          allowedTracingOrigins: ["http://ffvdh2.webmakers.ovh/","http://sdr.ffvoile.fr/"]
      });
      
      datadogLogs.setLoggerGlobalContext({ env:  window.VUE_DDOG_ENV })
      datadogRum.startSessionReplayRecording();
    }
  },
  
  beforeMount(){
    var pjson = require('../package.json');
    this.$store.dispatch('navigation/setVersion', pjson.version)
    if (window.VUE_ENABLE_DDOG == "true") this.enableDatadog(pjson.version)
  },

  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },

  beforeUnmount() {
    EventBus.remove("logout");
  },
};
</script>

<style lang="scss"> 
    @import "scss/_fonts.scss";
    @import "scss/_normalize.scss";
    @import "scss/_mediaqueries.scss";
    @import "scss/_responsive-layout.scss";
    @import "scss/_common-styles.scss";
    @import "scss/_common-blocs.scss";
    @import "scss/_pages.scss";
 </style> 
