<template>
    <div class="licensee-search-results page column">
      
        <HeaderComponent />

        <PageTitle pageTitle="recherche d'un licencié" />

        <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                    <!-- FIL D'ARIANNE -->

        <nav class="breadcrump">
            <ul class="breadcrump--container text-center">
                <li  class="item" title="Retourner à cette étape">
                    <router-link to="/results">Recherche d'épreuve</router-link>
                </li>
                <li class="item" title="Retourner à cette étape">
                    <router-link to="/results">Résultats de la recherche</router-link>
                </li>
                <li  class="item" title="Retourner à cette étape">
                    <router-link to="/checkout">Épreuve choisie : "{{ race.nom }}"</router-link>
                </li>
                <li class="current-item item" title="Vous êtes ici">
                    <span>Associer un ou des licenciés à l'épreuve</span>
                </li>
            </ul>
        </nav>



        <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                <!-- RECHERCHE LICENCIÉ -->

        <main class="main flex1 column mix-responsive-width">
            <p class="text-info need-mB2">Recherchez des licenciés et renseignez leur résultat sur cette épreuve</p>
            
            <section class="search-filters--container column row_center small-responsive-width">    
                <p class="search-filters--btn bold relative" @click="visibleSearchFilters = !visibleSearchFilters">Filtres <span :class="{'rotate' : visibleSearchFilters }" class="icon icon-arrow-down"></span></p>
                <div class="search-filters--content" v-if="visibleSearchFilters">

                    <!-- TOGGLE -->
                    <div class="row row_center center need-mB">
                        <input type="checkbox" id="advanced-search" class="checkbox" v-model="searchByLicenceNum">
                        <label class="btn--onoff" for="advanced-search"><span class="btn--onoff--text">Recherche par N° de licence</span></label>
                    </div>

                    <!-- RECHERCHE PAR NOM -->
                    <form class="width100 need-mT" v-if="!searchByLicenceNum">
                        <div class="input--container need-mB need-mT">
                            <span class="icon icon-diamond-rounded"></span>
                            <input 
                            id="inputFirstName"
                            type="text" 
                            placeholder="Prénom du licencié"
                            v-model="licenseeSearch.firstname"
                            >
                        </div>
                        <div class="input--container need-mB">
                            <span class="icon icon-location"></span>
                            <input
                            id="inputLastName"
                            type="text" 
                            placeholder="Nom du licencié"
                            v-model="licenseeSearch.lastname"
                            >
                        </div>

                        <button class="btn btn-large bg-mainColor" type="submit" @click.prevent="checkSearchByName()">
                            <span class="zindex">Recherche</span>
                        </button>

                        <div class="need-mT2" v-if="errorMessageName">
                            <p class="text-center orange-color">{{ errorMessageName }}</p>
                        </div>
                    </form>

                    <!-- RECHERCHE PAR LICENCE -->
                    <form  class="width100 need-mT2" v-else>
                        <!--p> 1410690U </p-->
                        <div class="input--container need-mB">
                            <span class="icon icon-diploma"></span>
                            <input
                            id="inputLicence"
                            type="text" 
                            placeholder="1234567A"
                            v-model="licenseeSearch.licenseNum"
                            >
                        </div>

                        <button class="btn btn-large bg-mainColor" @click.prevent="checkSearchByLicenseNum()">
                            <span class="zindex" @click="getTeamMembers">Recherche</span>
                        </button>

                        <div class="need-mT2" v-if="errorMessageLicense">
                            <p class="text-center">{{ errorMessageLicense }}</p>
                        </div>
                    </form>

                </div>
            </section>


            <LoadingSpinner v-show="loading" />
            


            <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                    <!-- SELECTIONNER UN LICENCIÉ -->

            <div class="responsive-wrap--flex need-mT2" v-if="licenseesResults" :class="{'onlychild' : licenseesResults.length == 1 }">
                
                <section class="card--container relative" v-for="licensee in licenseesResults" :key="licensee.noLicence" :class="{'hidden': selectedLicensee.noLicence && licensee.noLicence !== selectedLicensee.noLicence}">
                    <div class="selected-licensee licensee-card card bg-white need-mTB"
                    :class="{
                        'other-style' : selectedLicensee.noLicence,
                    }">
                        <div class="grid-2columns">
                            <div>
                                <p class="title">Prénom</p>
                                <p class="bold">{{ licensee.prenom }}</p>
                            </div>
                            <div>
                                <p class="title">Genre</p>
                                <p class="bold">{{ licensee.sexe }}</p>
                            </div>
                            <div>
                                <p class="title">Nom</p>
                                <p class="bold">{{ licensee.nom }}</p>
                            </div>
                            <div>
                                <p class="title">Naissance</p>
                                <p class="bold">{{ toDayAndMonth(licensee.dateNaissance,'DD/MM/YYYY')}}</p>
                            </div>
                            <div>
                                <p class="title">Club</p>
                                <p class="bold">{{ licensee.club }}</p>
                            </div>
                            <div>
                                <p class="title">N° de licence</p>
                                <p class="bold">{{ licensee.noLicence }}</p>
                            </div>
                        </div>
                        <button class="btn btn-large bg-mainColor need-mT" @click="selectLicensee(licensee)"><span class="zindex">Choisir ce licencié</span></button>
                    </div>
                </section>

                <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                        <!-- FORMULAIRE INFOS -->

                <section class="licensee-modification-section licensee-card card bg-white need-mTB" :class="{'visible': selectedLicensee.noLicence}">
                    <div class="licensee-modification-section--form">
                        <p class="text-info">Veuillez renseigner le rang de {{selectedLicensee.prenom}} {{selectedLicensee.nom}} sur l'épreuve :</p>
                        <p class="text-info need-mB">{{ race.nom }}</p>
                        <div class="input--container main-color-lighten need-mB">
                            <span class="icon icon-rank"></span>
                            <input 
                            id="rang"
                            type="text" 
                            placeholder="Rang du licencié"
                            >
                        </div>

                        <!-- ::: CHAMPS TEMPS : IMPLEMENTATION PLUS TARD ::: -->
                        <!-- <div class="input--container main-color-lighten need-mB">
                            <span class="bold uppercase">ou</span>
                            <input 
                            id="temps"
                            class="text-right"
                            type="text" 
                            placeholder="Temps du licencié"
                            v-model="dataModifications.Temps"
                            autocomplete="on" 
                            >
                            <span class="icon icon-right icon-time"></span>
                        </div> -->

                        <p v-if="errorDataLicense" class="orange-color need-mT text-center"> {{ errorDataLicense }}</p>

                    </div>
                </section>

                <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                <!-- CAROUSEL COÉQUIPIERS SÉLECTIONNÉS -->

                <section class="final-team-member--section horizontal-scroll-section need-mT2" v-if="licenseeTeamMembersAdded.length">
                    <p class="green-color text-center bold need-mB">Équipiers associés</p>
                    <p class="text-info need-mB">En associant ce(s) licencié(s) à {{ selectedLicensee.prenom }} {{ selectedLicensee.nom }}, vous leur donnez automatiquement le même rang.</p>
                    <section class="horizontal-scroll-container">
                        <div class="licensee-card-mini card bg-white column linked" :id="'team-card-' + teamMember.noLicence" v-for="(teamMember, index) in licenseeTeamMembersAdded" :key="index">
                            <p class="name bold">{{ teamMember.nom }} {{ teamMember.prenom }}</p>
                            <p class="main-color-lighten need-mTB5">{{ teamMember.sexe }} - {{ toDayAndMonth(teamMember.dateNaissance,'DD/MM/YYYY')}} - {{ teamMember.noLicence }}</p>
                            <p class="main-color-lighten">{{ teamMember.club }}</p>
                            <div class="self-center row row__center need-mT">
                                <button class="btn bg-white_mainColorLighten remove-btn" @click="removeTeamMember(teamMember)"><span class="zindex">Retirer</span></button>
                            </div>
                        </div>
                    </section>
                </section>

                <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                        <!-- ÉQUIPAGE -->

                <section class="search-filters--container column row_center equipage-container large-responsive-width need-mT2" :class="{'expand': selectedLicensee.noLicence}">    
                    <p class="search-filters--btn bold relative" @click="manageTeamMembers = !manageTeamMembers">Équipage<span :class="{'rotate' : manageTeamMembers }" class="icon icon-arrow-down"></span></p>
                    <div class="team-member--container" v-if="manageTeamMembers">
                        <p class="text-info need-mB">Vous souhaitez assigner le même rang à un ou plusieurs autres licenciés ?</p>

                        <!-- TAB SELECTION -->
                        <div class="column center need-mT2"> <!-- search-team-member-btn--container -->
                            <button class="btn btn-short main-color-lighten need-mB" :class="{'bg-mainColorLighten' : tab === 1, 'bg-white_mainColorLighten' : tab === 2}" v-if="licenseeTeamMembersFound.length" @click="toggleTab1()"><span class="zindex">Équipiers suggérés</span></button>
                            <button class="btn btn-short main-color-lighten" :class="{'bg-mainColorLighten' : tab === 2, 'bg-white_mainColorLighten' : tab === 1}" @click="toggleTab2()"><span class="zindex">Chercher un équipier</span></button>
                        </div>


                        <div class="need-mT2" :class="{ 'visible' : tab != 0 }" :id="selectedLicensee.noLicence + '-team-members-insert'">

                            <!-- ÉQUIPIERS SUGGÉRÉS -->
                            <section class="horizontal-scroll-section need-mT need-mB2">
                                <p class="text-info need-mB" v-show="licenseeTeamMembersFound.length && tab == 1">Équipiers suggérés</p>
                                <p class="text-info orange-color need-mB" v-show="!licenseeTeamMembersFound.length && tab == 1">Nous n'avons pas trouvé de coéquipier suggéré</p>
                                <div class="horizontal-scroll-container" v-show="tab == 1">
                                    <div class="licensee-card-mini card bg-white column" :id="'team-card-' + teamMember.noLicence" v-for="(teamMember, index) in licenseeTeamMembersFound" :key="index">
                                        <p class="name bold">{{ teamMember.nom }} {{ teamMember.prenom }}</p>
                                        <p class="main-color-lighten need-mTB5">{{ teamMember.sexe }} - {{ toDayAndMonth(teamMember.dateNaissance,'DD/MM/YYYY')}} - {{ teamMember.noLicence }}</p>
                                        <p class="main-color-lighten">{{ teamMember.club }}</p>
                                        <div class="self-center row row__center need-mT">
                                            <input type="checkbox" class="check--input" :id="teamMember.noLicence + '-team-members-checked'" :value="teamMember" v-model="licenseeTeamMembersAdded" @click="addTeamMember($event, teamMember)"/>
                                            <label class="check--container row row_center need-mT" :for="teamMember.noLicence + '-team-members-checked'">
                                                <span class="check--icon icon-check-square"></span>
                                                <span class="check--text"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <!-- AJOUTER UN ÉQUIPIER NON SUGGÉRÉ -->
                            <section class="column row_center small-responsive-width need-mB2" v-show="tab == 2">    
                                <div class="search-filters--content">
                                    <p class="text-info need-mT need-mB">Vous pouvez chercher un autre licencié ici pour l'associer à {{ selectedLicensee.prenom }} {{ selectedLicensee.nom }}.</p>

                                    <!-- TOGGLE -->
                                    <div class="row row_center center need-mB">
                                        <input type="checkbox" id="advanced-search" class="checkbox" v-model="searchByLicenceNum2">
                                        <label class="btn--onoff" for="advanced-search"><span class="btn--onoff--text">Recherche par N° de licence</span></label>
                                    </div>

                                    <!-- RECHERCHE PAR NOM -->
                                    <form class="width100 need-mT " v-if="!searchByLicenceNum2">
                                        <div class="input--container need-mB need-mT">
                                            <span class="icon icon-diamond-rounded"></span>
                                            <input 
                                            id="inputFirstName"
                                            type="text" 
                                            placeholder="Prénom du licencié"
                                            v-model="teamMemberSearch.firstname"
                                            >
                                        </div>
                                        <div class="input--container need-mB">
                                            <span class="icon icon-location"></span>
                                            <input
                                            id="inputLastName"
                                            type="text" 
                                            placeholder="Nom du licencié"
                                            v-model="teamMemberSearch.lastname"
                                            >
                                        </div>

                                        <button class="btn btn-large bg-mainColor" type="submit" @click.prevent="checkSearchByName2()">
                                            <span class="zindex">Recherche</span>
                                        </button>

                                        <div class="need-mT2" v-if="errorMessageName2">
                                            <p class="text-center orange-color">{{ errorMessageName2 }}</p>
                                        </div>
                                    </form>

                                    <!-- RECHRCHE PAR LICENCE -->
                                    <form class="width100 need-mT2" v-else>
                                        <div class="input--container need-mB">
                                            <span class="icon icon-diploma"></span>
                                            <input
                                            id="inputLicence"
                                            type="text" 
                                            placeholder="1234567A"
                                            v-model="teamMemberSearch.licenseNum"
                                            >
                                        </div>

                                        <button class="btn btn-large bg-mainColor" type="submit" @click.prevent="checkSearchByLicenseNum2()">
                                            <span class="zindex" @click="getNewLicensee">Recherche</span>
                                        </button>

                                        <div class="need-mT2" v-if="errorMessageLicense2">
                                            <p class="text-center">{{ errorMessageLicense2 }}</p>
                                        </div>
                                    </form>
                                </div>
                            </section>

                            <!-- RESULTATS RECHRCHE COEQUIPIER -->
                            <section class="horizontal-scroll-section need-mT2 need-mB2" v-show="tab == 2">
                                <div v-show="newTeamMemberResults.length > 0">
                                    <p class="text-info">Résultat(s) de la recherche :</p>
                                    <div class="horizontal-scroll-container">
                                        <div class="licensee-card-mini card bg-white column" :id="'team-card-' + teamMember.noLicence" v-for="teamMember in newTeamMemberResults" :key="teamMember.id">
                                            <p class="name bold">{{ teamMember.nom }} {{ teamMember.prenom }}</p>
                                            <p class="main-color-lighten need-mTB5">{{ teamMember.sexe }} - {{ toDayAndMonth(teamMember.dateNaissance,'DD/MM/YYYY')}} - {{ teamMember.noLicence }}</p>
                                            <p class="main-color-lighten">{{ teamMember.club }}</p>
                                            <div class="self-center row row__center need-mT" v-show="!alreadySelected(teamMember)">
                                                <input type="checkbox" class="check--input" :id="teamMember.noLicence + '-team-members-checked'"
                                                    :value="teamMember"
                                                    v-model="licenseeTeamMembersAdded"
                                                    @click="addTeamMember($event, teamMember), manageTeamMembers = !manageTeamMembers"
                                                />
                                                <label class="check--container row row_center need-mT" :for="teamMember.noLicence + '-team-members-checked'">
                                                    <span class="check--icon icon-check-square"></span>
                                                    <span class="check--text"></span>
                                                </label>
                                            </div>
                                            <span class="self-center row row__center need-mT darkgrey-color" v-show="alreadySelected(teamMember)">
                                                Déjà sélectionné
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>

                    <!-- <aside class="team-members-container" :class="{'min-height' : toggleTeamMembersInsert}" v-if="selectedLicensee.noLicence">
                    </aside> -->

                    </div>
                </section>

            </div>
 
        </main>

        <footer class="pagination">
            <router-link to="/checkout" class="btn need-mT2"><span class="zindex">Annuler</span></router-link>
            <button class="btn btn-short-medium bg-mainColorLighten need-mT2" @click="saveModifications()" ><span class="zindex">Valider</span></button>
        </footer>

    </div> 
</template>


<script>
import { mapGetters } from 'vuex'
import apiService from '@/services/api.service'

import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue'

export default {
    name: 'LicenseeSearchResults',

    components: {
        HeaderComponent,
        PageTitle,
        LoadingSpinner,
    },

    props: ['raceId'],

    data(){
        return{            
            // Style :
            loading: false,
            searchByLicenceNum : false,
            searchByLicenceNum2 : false,
            toggleModifInsert: false,
            visibleSearchFilters : true,
            tab: 0,
            error: "",

            // RECHERCHE LICENCIÉ
            // Recherche de licenciés
            licenseeSearch : {
                firstname: "",
                lastname : "",
                licenseNum : "",
            },

            // Resultats de la recherche de licenciés
            licenseesResults : [],

            // Coéquipiers potentiels
            visibleTeamMembers : false,

            // Licencié sélectionné
            selectedLicensee: {},

            // Rendre visible l'encart recherche d'équipier
            toggleTeamMembersInsert : false,

            // Équipiers associés
            selectedTeamMembers: [],

            // DATA
            // temps ou rang sur l'épreuve
            rank: "",
            // time: "",

            // RECHERCHE COÉQUIPIERS
            // Équipage possible
            loadingTeamMembers: false,
            licenseeTeamMembersFound : [],

            licenseeTeamMembersAdded : [],
            
            //Style des équipiers séléctionnés :
            styleCheck : false,

            // Recherche coéquipier
            teamMemberSearch : {
                firstname: "",
                lastname : "",
                licenseNum : "",
            },

            newTeamMemberResults: [],

            // ERRORS
            // Validation du rang ou temps renseigné
            errorDataLicense : "",
            errorMessageName : "",
            errorMessageLicense : "",

            errorDataLicense2 : "",
            errorMessageName2 : "",
            errorMessageLicense2 : "",

            manageTeamMembers: false
        }
    },

    computed:{
        ...mapGetters({
            race: 'changes/getTargetRace'
        }),

        getUsersResultsByName(){
            return this.$store.state.auth.user.login
        },
        
        toDayAndMonth() {
            return (value, formatDate)=>{
                return this.$dayjs(value).format(formatDate)
            }
        },

        selectedMembers(){
            return [this.selectedLicensee, ... this.licenseeTeamMembersAdded]
        }
    },

    methods: {

       /**
        *  ---------
        *  AFFICHAGE
        *  ---------
        */
        
        //Afficher l'encart pour renseigner le temps ou le rang au click sur une des épreuves
        selectLicensee(licensee){
            // fix bug : le rang reste après avoir validé une première fois
            const input = document.getElementById('rang')
            if(input && input.value) input.value = ""

            // var licensee = document.getElementById(id)
            // var licenseeModification = document.getElementById(id + '-modif')
            // licensee.classList.toggle('other-style')
            // licenseeModification.classList.toggle('visible')

            // this.selectedLicensee = this.licenseesResults.filter((member) => member.noLicence === id)[0];
            this.selectedLicensee = licensee
            console.log(this.selectedLicensee)
            this.getLicenseeTeamMembers(licensee.noLicence)
        },
        
        toggleTab1(){
            this.tab = 1
        },
        toggleTab2(){
            this.tab = 2
        },


        resetSearchLicencee(){
            // Remets les filtres de recherche à 0
            // Vide les tableaux de résultats : licenciés et équipiers possibles
            // Cache l'encart
            // Ouvre l'encart filtres de recherche
           
            this.licenseeSearch.firstname = ""
            this.licenseeSearch.lastname  = ""
            this.licenseeSearch.licenseNum = ""
            this.rank = "" 
            this.time = ""
            this.visibleSearchFilters = true
            this.licenseesResults = []
            this.toggleModifInsert = false
            this.licenseeTeamMembersFound = []
        },

    // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

       /**
        *  -----------------------
        *  RECHERCHE D'UN LICENCIÉ
        *  -----------------------
        */

        // Recherhe d'un licencié par nom et prénom
        checkSearchByName(){
            this.selectedLicensee = {}
            this.loading = true
            if (!this.licenseeSearch.firstname || !this.licenseeSearch.lastname ){
                this.errorMessageName = "Merci de renseigner les 2 champs"
                this.loading = false
            } else {
                this.getLicensieesByName()
                this.errorMessageName = ""
            }
        },

        getLicensieesByName(){
            this.licenseesResults = []
            this.licenseeTeamMembersAdded = []
            var dataToSend = {
                "prenom": this.licenseeSearch.firstname,
                "nom": this.licenseeSearch.lastname,
            }

            apiService.getLicensieesByName(dataToSend).then(
                (response) => {
                    this.loading = false
                    this.licenseesResults = response.data
                    if( this.licenseesResults){
                        if (this.licenseesResults.length == 0){
                            this.errorMessageName = "Nous n'avons trouvé aucun licencié correspondant à votre recherche"
                        } else {
                            this.errorMessageName = ""
                            this.visibleSearchFilters = false
                        }
                    } else {
                         this.errorMessageName = "Une erreur s'est produite, veuillez recommencer"
                    }
                },
                error => {
                    this.loading = false;
                    console.log((error.response && error.response.data) || error.message || error.toString())
                    this.errorMessageName = 
                    (error.response && error.response.data) || error.message || error.toString();
                }
            )
        },
 
        //Recherche d'un licencié par n° de licence
        checkSearchByLicenseNum(){
            this.selectedLicensee = {}
            this.loading = true
            if (!this.licenseeSearch.licenseNum ){
                this.errorMessageLicense = "Merci de renseigner le numéro de licence"
                this.loading = false
                
            } else {
                this.getLicensieesByLicenseNum()
                this.errorMessageLicense = ""
            }
        },

        // test : 1437974Q
        getLicensieesByLicenseNum(){
            this.licenseesResults = []
            this.licenseeTeamMembersAdded = []
            apiService.getLicensieesByLicenseNum(this.licenseeSearch.licenseNum).then(
                (response) => {
                    this.loading = false

                    // L'API renvoie un code 200 même si la réponse est vide
                    // -> erreur à la mano
                    if(response.data.length === 0){
                        this.errorMessageLicense = "Nous n'avons trouvé aucun licencié associé à ce numéro"
                    }
                    else {
                        this.licenseesResults.push(response.data[0])
                        this.errorMessageLicense = ""
                        this.visibleSearchFilters = false
                    }
                },
                (error) => {
                    this.loading = false;
                    console.log((error.response && error.response.data) || error.message || error.toString())
                    this.errorMessageLicense = 
                    (error.response && error.response.data) || error.message || error.toString();
                }
            )
            
        },

    // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

       /**
        *  ----------------------
        *  COÉQUIPIERS POTENTIELS
        *  ----------------------
        */

        //Récupérer la liste des équipiers possibles
        getLicenseeTeamMembers(licenseId){
            apiService.getLicenseeTeamMembers(licenseId).then(
                (response) => {
                    this.licenseeTeamMembersFound = response.data
                },
                error => {
                    console.log((error.response && error.response.data) || error.message || error.toString())
                    this.errorMessageLicense = 
                        (error.response && error.response.data) || error.message || error.toString();
                }
            )
        },


    // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

       /**
        *  --------------------
        *  RECHERCHE COÉQUIPIER
        *  --------------------
        */

        // Recherhe d'un licencié par nom et prénom
        checkSearchByName2(){
            this.loading = true
            if (!this.teamMemberSearch.firstname || !this.teamMemberSearch.lastname ){
                this.errorMessageName2 = "Merci de renseigner les 2 champs"
                this.loading = false
            } else {
                this.getLicensieesByName2()
                this.errorMessageName2 = ""
            }
        },

        // Recherche licencié à associer
        getLicensieesByName2(){
            this.newTeamMemberResults = []
            var dataToSend = {
                "prenom": this.teamMemberSearch.firstname,
                "nom": this.teamMemberSearch.lastname,
            }

            apiService.getLicensieesByName(dataToSend).then(
                (response) => {
                    this.loading = false
                    this.newTeamMemberResults = response.data
                    if(this.newTeamMemberResults){
                        if (this.newTeamMemberResults.length == 0){
                            this.errorMessageName2 = "Nous n'avons trouvé aucun licencié correspondant à votre recherche"
                        } else {
                            this.errorMessageName2 = ""
                            this.visibleSearchFilters = false
                        }
                    } else {
                        this.errorMessageName2 = "Une erreur s'est produite, veuillez recommencer"
                    }
                },
                error => {
                    this.loading = false;
                    console.log((error.response && error.response.data) || error.message || error.toString())
                    this.errorMessageName2 = 
                    (error.response && error.response.data) || error.message || error.toString();
                }
            )
        },
 
        //Recherche d'un licencié par n° de licence
        checkSearchByLicenseNum2(){
            this.loading = true
            if (!this.teamMemberSearch.licenseNum ){
                this.errorMessageLicense2 = "Merci de renseigner le numéro de licence"
                this.loading = false
                
            } else {
                this.getLicensieesByLicenseNum2()
                this.errorMessageLicense2 = ""
            }
        },

        // test : 1437974Q
        getLicensieesByLicenseNum2(){
            this.newTeamMemberResults = []
            apiService.getLicensieesByLicenseNum(this.teamMemberSearch.licenseNum).then(
                (response) => {
                    this.loading = false

                    // L'API renvoie un code 200 même si la réponse est vide
                    // -> erreur à la mano
                    if(response.data.length === 0){
                        this.errorMessageLicense2 = "Nous n'avons trouvé aucun licencié associé à ce numéro"
                    }
                    else {
                        this.newTeamMemberResults.push(response.data[0])
                        this.errorMessageLicense2 = ""
                        this.visibleSearchFilters = false
                    }
                },
                (error) => {
                    this.loading = false;
                    console.log((error.response && error.response.data) || error.message || error.toString())
                    this.errorMessageLicense2 = 
                    (error.response && error.response.data) || error.message || error.toString();
                }
            )
            
        },

    // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

       /**
        *  -------------------
        *  GESTION DES DONNÉES
        *  -------------------
        */

        alreadySelected(member){
            return this.selectedMembers.filter((el) => el.noLicence === member.noLicence).length > 0
        },

        // ajoute ou supprime le coéquipier dans this.licenseeTeamMembersAdded
        addTeamMember(e, member){
            // vérifie que le licencié sélectionné ne fait pas déjà parti des équipiers ajoutés
            const check = this.selectedMembers.find((el) => el.noLicence === member.noLicence)
            if(!check){
                if(e.target.checked){
                    this.licenseeTeamMembersAdded.push(member)
                }
                else {
                    this.licenseeTeamMembersAdded = this.licenseeTeamMembersAdded.filter((addedMember) => {
                        return addedMember.nom !== member.nom && addedMember.prenom !== member.prenom 
                    })
                }
            }
        },

        removeTeamMember(member){
            const filtered = this.licenseeTeamMembersAdded.filter((m) => {
                return m.noLicence != member.noLicence
            })
            this.licenseeTeamMembersAdded = filtered
        },

        //Vider le tableau des équipiers associés au click sur "annuler"
        resetTeamMembersAdded(){
            this.toggleTeamMembersInsert = false 
            this.licenseeTeamMembersAdded = []
        },

        saveModifications(){
            const rang = document.getElementById("rang").value
            // const temps = document.getElementById("temps").value

            if(!rang){
                this.errorDataLicense = "Veuillez renseigner le rang du licencié"
                document.querySelector('.selected-licensee').scrollIntoView({behavior: "smooth", block: "start"})
            }
            else {
                this.errorDataLicense = ""

                const data = this.selectedMembers

                data.forEach((member) => {
                    member.Rang = rang
                })
                
                this.$store.dispatch('changes/addLicensees', data)
                this.$router.push('/checkout')
            }


            // ::: CHAMPS TEMPS : IMPLEMENTATION PLUS TARD :::

            // if (this.rank && this.time){
            //     this.errorDataLicense = "Veuillez ne renseigner qu'un seul des 2 champs"
            // }
            // else if (!this.rank && !this.time){
            //     this.errorDataLicense = "Veuillez renseigner le rang ou le temps du licencié"
            // }
            // else {
            //     this.errorDataLicense = ""

            //     const data = this.selectedMembers
            //     data.forEach((member) => {
            //         if(this.dataModifications.Rang){
            //             member.Rang = this.dataModifications.Rang
            //         }
            //         else {
            //             member.Temps = this.dataModifications.Temps
            //         }
            //     })
                
            //     this.$store.dispatch('changes/addLicensees', data)
            //     this.$router.push('/checkout')
            // }
        },
    }
}
</script>

<style>
.need-mB1-i {
    margin-bottom: 1em !important;
    width: unset !important;
}
.equipage-container {
    display: none;
}
.equipage-container.expand {
    display: flex;
}
/*.equipage-container .btn-line {
    flex-direction: column;
    justify-content: center;
}
.equipage-container .btn-line .btn {
    margin: 1rem 0 0 !important;
}*/
.horizontal-scroll-container {
    background-color: white;
    box-shadow: 0px 0px 30px 20px white;
}
.hidden {
    display: none;
}
</style>