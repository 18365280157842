export default class TransmissionResultatsEmpty {
  
  constructor(){ 
    this.enveloppe =
    { Fichier : {
        Format : 3, //const 
        Date : null, //Date action
        Kernel: {
          Classe : "ffvoile", //const
          Name : "SDR", // == "saisie des résultats" - constante
          Major : 0, // npm package version majeur
          Minor : 0, // npm package version mineur
        },
        User : {
          NoLicence : "", // $store.user.numlicence
          Nom : "",// $store.user.nom
          Prenom : ""// $store.user.prenom
        }
      },
      Entete :{
        /*Responsable: {
          Email : "",
          NoLicence : "",
          Identifiant :  "",
          Password :  "",
          Nom :  "",
          Prenom :  ""
        },
        ResultatPartiel : true,
        FichierCoureur :{
          Name :  "" ,
          Date :  "" ,
        }*/
      },
      Competition :{
        CoId : 0, // -> A ajouter dans la recherche : Julien
        Epreuves: [
          {
            EpId : 0,
            Nom :  "",
            DateDebut :  "",
            DateFin :  "",
            Structure :  "", //stCode
            CodeBateau :  "",
            Grade :  "",
            VerifLink : "", // (saisie non obligatoire)
            Organisateurs : [
              /*{
                fonction :  "",
                NoLicence :  "",
                Identifiant :  "",
                Password :  "",
                Nom :  "",
                Prenom :  ""
              }*/
            ],
            Resultats_Groupes: [
              {
                //Code :  "", default ou à virer ? 
                //Libelle :  "", default ou à virer ?
                CodeBateau :  "", // idem que bateau épreuve
                //Date_Calcul : "2022-02-18T17 =04 =07.299Z", default ou à virer ?
                NbClasses : null, // Nbr de coureurs: saisie obligatoire si null
                NbManches_valides : null, // Nbr de courses: saisie obligatoire si null
                NbNations : null, // (saisie non obligatoire)
                Manches : [
                  /*{
                    Manche_Id : 0,
                    Ordre : 0,
                    Libelle :  "",
                    Date :  "",
                    Heure :  "",
                    Type :  "",
                    Obligatoire : true,
                    Coefficient : 0,
                    CVL : true,
                    Mode :  "",
                    Longueur : 0
                  }*/
                ],
                Bateaux : [
                  {
                    Rang :  "", //Soit le rang soit le temps : obligatoire
                    Temps :  "",
                    /*NumVoile :  "",
                    Nom :  "",
                    Points : 0,
                    Groupe :  "",
                    TypeBateau :  "",
                    Reference : 0,
                    TypeRating :  "",
                    Rating : 0,
                    RatingBrut : 0,
                    Experimental : true,
                    CVL : 0,
                    AgeCategorie :  "",
                    Sexe :  "",*/
                    Equipage : [
                      { //Recherche licencié:
                        //Role :  "", A voir + tard
                        Sexe :  "",
                        Club :  "",
                        NoLicence :  "",
                        Nom :  "",
                        Prenom :  ""
                      }
                    ],
                    Manches : [
                      /*{
                        Manche_Id : 0,
                        Place : 0,
                        DecisionJury :  "",
                        Treel :  "",
                        TCorrige :  "",
                        TComp :  "",
                        NbTours : 0,
                        Distance : 0
                      }*/
                    ]
                  }
                ],
              }
            ]
          }
        ]
      }
    }
    this.enveloppe.Competition.Epreuves = []
  }
}