<template>
    <section class="popup--container">
        <div class="bg-blur"></div>
        <div class="popup bg-orange">
            <span class="icon icon-exclamation-rounded"></span>
            <p class="need-mTB2 text-center">{{textPopup}}</p>
            <div class="row row_center">
                <button class="popup-btn" @click="this.$emit('closePopUp')">D'accord</button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'OrangePopUp',
  props: {
    textPopup: String,
  }
}
</script>