<template>
    <div class="useful-links page column">
      
        <HeaderComponent />
        <PageTitle pageTitle="liens utiles" />
        <main class="main flex1 column small-responsive-width">
            <p class="italic text-center need-mB2">Les résultats remontés par ce canal sont pris en compte chaque nuit avant d’être affichés sur le calendrier fédéral</p>
            <section class="small-card row between">
                <div>
                    <p class="bold need-mB5">Calendrier Fédéral</p>
                    <p>Calendrier des Compétitions FFVoile</p>
                </div>
                <a href="https://calendrier.ffvoile.fr" target="_blank"><span class="icon icon-eye-square"></span></a>
            </section>
        </main>
        <section class="pagination">
            <router-link to='/' class="btn"><span class="zindex">Retour</span></router-link>
        </section>
    </div> 
</template>


<script>
import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'

export default {
    name: 'UsefullLinks',
    components: {
        HeaderComponent,
        PageTitle,
    },
}
</script>