import api from './api';

class ApiService {

  //Log
  login = (user) =>  api.post('account/login',user,{withCredentials: true})
  
  //Races

  // Recherche d'épreuve
  raceSearch = (dataToSend) =>  api.post('epreuves', dataToSend)
  
  // Récupère les résultats transmis auparavant
  getRaceResults = (raceId) =>  api.get(`epreuves/${raceId}/resultats`)
  
  // Envoi des résultats
  sendData = (dataTosent)  =>  api.post('resultats', dataTosent)
  
  // Historique d'un entraineur
  getUserHistory = () =>  api.get(`history`)
  
  // Récupère les courses modifiées par l'utilisateur
  getRaceByRaceId = (raceId) =>  api.get(`epreuves/${raceId}`)
  

  


  getSupportList = () =>  api.get(`configuration/supports`)
  getLevelList = () =>  api.get(`configuration/niveaux`)
  getGradeList = () =>  api.get(`configuration/grades`)
  

  //Licensees
  getLicensieesByName = (dataToSend) =>  api.post('licencies', dataToSend)
  getLicensieesByLicenseNum = (licenseNum) =>  api.get(`licencies/${licenseNum}`)
  getLicenseeTeamMembers = (licenseNum) =>  api.get(`licencies/${licenseNum}/equipiers`)
  
}

export default new ApiService();