<template>
  <div class="race-search page column">

    <HeaderComponent />
    <PageTitle pageTitle="résultats de la recherche" />

    <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                <!-- FIL D'ARIANNE -->
                                
    <nav class="breadcrump">
        <ul class="breadcrump--container text-center">
            <li class="item" title="Retourner à cette étape">
                <router-link to="/">Recherche d'épreuve</router-link>
            </li>
            <li class="current-item item" title="Vous êtes ici">
                <router-link to="/checkout">Résultats de la recherche</router-link>
            </li>
        </ul>
    </nav>

    <YellowPopUp v-show="showYellowPopup"
      textPopup="Des modifications ont été enregistrées. Sélectionner cette course effacera les données en attente d'envoi."
      txtBtn1="Ignorer"
      :action1="selectAnyway"
      txtBtn2="Voir les données enregistrées"
      :action2="goToCheckout"
    />

    <RedPopUp v-show="showRedPopup" textPopup="Une erreur est survenue avec cette course." @closePopUp="showRedPopup = false"/>

    <LoadingSpinner v-show="loading" />

    <main class="main flex1 column large-responsive-width">

      <section id="race-result-list" class="responsive-wrap--grid" :class="{ 'onlychild' : raceSearchResults.length == 1 }">
          <article class="race-card card need-mTB" v-for="raceResult in raceSearchResults" :key="raceResult.id">
              <div class="row between align-start">
                  <h2 class="title2">{{ raceResult.nom }}</h2>
                  <img title="Résultats disponibles" src="../assets/trophy.svg" class="img need-mL5" v-show="raceResult.resultatsTransmis" />
              </div>
              <div class="bg-lightGrey inside-card need-mTB">
                  <p v-show="raceResult.organisateur">
                      <span class="icon icon-star"></span>
                      Organisé par : {{ raceResult.organisateur }}
                  </p>
                  <p v-show="raceResult.lieu">
                      <span class="icon icon-location"></span>
                      Lieu : {{ raceResult.lieu }}
                  </p>
                  <p v-show="raceResult.hnLieu">
                      <span class="icon icon-diamond-rounded"></span>
                      {{ raceResult.hnLieu }}
                  </p>
                  <p v-show="raceResult.dateDebut && raceResult.dateFin">
                      <span class="icon icon-calendar"></span>
                      Début : {{ toDayAndMonth(raceResult.dateDebut,'DD/MM/YYYY') }} • Fin : {{ toDayAndMonth(raceResult.dateFin,'DD/MM/YYYY') }}
                  </p>
                  <p v-show="raceResult.epreuveId">
                      <span class="icon icon-diploma"></span>
                      Code : {{ raceResult.epreuveId }}
                  </p>
                  <p v-show="raceResult.grade">
                      <span class="icon icon-grade"></span>
                      Grade : {{ raceResult.grade }}
                  </p>
                  <p v-show="raceResult.hnNiveau">
                      <span class="icon icon-level"></span>
                      Niveau : {{ raceResult.hnNiveau }}
                  </p>
                  <p v-show="raceResult.support">
                      <span class="icon icon-boat"></span>
                      {{ raceResult.support }}
                  </p>
                  <p class="bold main-color-lighten">
                      <span class="icon icon-user"></span>
                      Nombre de coureurs : <span class="need-mL5" v-if="raceResult.nbCoureurs">{{ raceResult.nbCoureurs }}</span><span class="need-mL5" v-else>A renseigner</span>
                  </p>
                  <p class="bold main-color-lighten">
                      <span class="icon icon-itinerary"></span>
                      Nombre de courses : <span class="need-mL5" v-if="raceResult.nbManches">{{ raceResult.nbManches }}</span><span class="need-mL5" v-else>A renseigner</span>
                  </p>
                  <p>
                      <span class="icon icon-flag"></span>
                      Nombre de nations : <span class="need-mL5" v-if="raceResult.nbNations">{{ raceResult.nbNations }}</span><span class="need-mL5" v-else>A renseigner</span>
                  </p>
                  <p class="own-style">
                      <span class="icon icon-link"></span>
                      Lien de vérif : <a :href="raceResult.urlVerif" target="_blank" class="need-mL5 link--hover-underline" v-if="raceResult.urlVerif">{{ raceResult.urlVerif}}</a><span class="need-mL5" v-else>A renseigner</span>
                  </p>
                  <p v-show="raceResult.urlEpreuve" class="own-style">
                      <span class="icon icon-web"></span>
                      <span cass="row row_center wrap need-mR5">Site : <a :href="raceResult.urlEpreuve" target="_blank" class="link--hover-underline break-all">{{ raceResult.urlEpreuve}}</a></span>
                  </p>
              </div>
              <button @click="selectRace(raceResult)" class="btn btn-large bg-mainColorLighten need-mT"><span class="zindex">Choisir cette épreuve</span></button>
          </article>
      </section>
    </main>
  </div>

  <footer class="pagination">
    <router-link class="btn btn-short-medium need-mT2 new-search-btn" to="/"><span class="zindex">Nouvelle recherche</span></router-link>
  </footer>

</template>

<script>
import { mapGetters } from "vuex"
import apiService from '@/services/api.service'

import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'
import YellowPopUp from '@/components/popups/YellowPopUp.vue'
import RedPopUp from '@/components/popups/RedPopUp.vue'
import LoadingSpinner from '../components/elements/LoadingSpinner.vue'

export default {
  components: {
    HeaderComponent,
    PageTitle,
    YellowPopUp,
    RedPopUp,
    LoadingSpinner,
  },

  data(){
    return {
      loading: true,
      showYellowPopup: false,
      showRedPopup: false,
      raceSearchResults: [],
      selectedRace: false
    }
  },

  beforeMount(){
    this.getSearchResults()
  },

  computed: {
    ...mapGetters({
      hasEdit: 'changes/hasEdited',
      history: 'navigation/history',
      targetRace: 'changes/getTargetRace'
    }),

    toDayAndMonth(){
      return (value, formatDate)=>{
        return this.$dayjs(value).format(formatDate)
      }
    },
  },

  methods: {

    /** 
      *  ----------
      *  NAVIGATION
      *  ----------
      */

    async getSearchResults(){

      try {
        let response = await apiService.raceSearch(this.history);
        this.raceSearchResults = response.data
        this.loading = false
        if(this.raceSearchResults){
          if (this.raceSearchResults.length == 0){
            this.errorMessageRaceSearch = "Nous n'avons trouvé aucune épreuve correspondant à votre recherche"
          }
          else {
            this.$router.push('/results')
          }
        }
        else {
          this.errorMessageRaceSearch = "Une erreur s'est produite, veuillez recommencer"
        }  
      }
      catch {
        this.errorMessageRaceSearch = "Une erreur s'est produite, veuillez recommencer"
      }
    },

    async selectRace(race){
      if(this.hasEdit){
        this.showYellowPopup = true
        this.selectedRace = race
      }
      else {
        try {
          this.$store.dispatch('changes/reset')
          this.$store.dispatch('changes/setRace', race)
          const response = await apiService.getRaceResults(race.epreuveId)
          this.$store.dispatch('changes/setRaceResultsFromApi', response)
          this.$router.push('/checkout')
        }
        catch {
          this.showOrangePopup = false
          this.showRedPopup = true
        }
      }
    },

    // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

    /** 
     *  --------------
     *  METHODES POPUP
     *  --------------
      */
     
     goToCheckout(){
       this.$router.push('/checkout')
      },

    async selectAnyway(){
      const race = this.selectedRace
      try {
        this.$store.dispatch('changes/reset')
        this.$store.dispatch('changes/setRace', race)
        const response = await apiService.getRaceResults(race.epreuveId)
        this.$store.dispatch('changes/setRaceResultsFromApi', response)
        this.$router.push('/checkout')
      }
      catch {
        this.showOrangePopup = false
        this.showRedPopup = true
      }
    },
  }

}
</script>

<style scoped>
.new-search-btn{
  background-color: #002a67 !important;
}
.zindex {
  z-index: 0;
}
</style>