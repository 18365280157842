<template>
  <div class="page logout popup--container">
     <div class="bg-blur"></div>
        <div class="popup" >
          <div v-if="!loading">
            <div class="need-mB2">
                <p class="title3 text-nowrap">Êtes-vous sûr de vouloir</p>
                <p class="title2 text-nowrap">Vous déconnecter ?</p>
            </div>
            <div class="width100 row row_center evenly">
                <router-link to="/profile" class="btn btn-short bg-white"><span class="zindex">Non</span></router-link>
                <button class="btn btn-short bg-mainColor" @click="logout()"><span class="zindex">Oui</span></button>
            </div>
          </div>
            <LoadingSpinner v-else/>
        </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue'

export default {
  name: 'LogoutView',
  components: {
    LoadingSpinner
  },
  data() {
    return{
      loading : false,
    }
  },
  methods: {
    logout() {
      this.loading = true;
      this.message = "";
          this.$store.dispatch('auth/logout').then(
            () => {
              this.$router.push('/login');
            },
            error => {
              this.loading = false;
              this.message = 
                (error.response && error.response.data) || error.message || error.toString();
            }
          )
    }
  },
};
</script>