import apiService from "@/services/api.service";
import storageService from "@/services/storage.service";
import JwtParser
    from "@/services/jwtparser.service";

const user = storageService.get();
const initialState = user
    ? { loggedIn: true, user :user}
    : { loggedIn: false, user: null};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        async login({ commit }, user) {
            try {
                let logResponse = await apiService.login(user)
                commit('loginSuccess', logResponse.data);
                storageService.update(logResponse.data);
                return Promise.resolve(user);
            } catch (error) {
                console.log(error)
                commit('loginFailure');
                storageService.removeUser();
                return Promise.reject(error);
            }
        },
    
        logout({ commit }) {
            storageService.removeUser();
            commit('logout');
        },

        refreshToken({ commit }, token) {
            storageService.updateToken(token);
            commit('refreshToken', token);
        },
        updateStore({ commit }, newUser) {
            commit ('updateLocalUser', newUser)
        }
    },
    getters: {
        currentUser: (state) => state.user,
        isLogged: (state) => state.isLoggedIn,
        sessionExpired: (state) => {
            if (state.user && state.user.token) {
                let roles = JwtParser.getJson(state.user.token)
                return roles.exp >= new Date()
            }
            return true;
        },
    },
    mutations: {
        loginSuccess(state, user) {
            state.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.loggedIn = false;
            state.user = null; 
        },
        refreshToken(state, token) {
            state.loggedIn = true;
            state.user = { ...state.user, token: token };
        },
    }
};
