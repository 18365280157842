<template>
    <div class="edit-result page column">
      
        <HeaderComponent />
        <PageTitle pageTitle="Modifier les résultats" />
        <nav class="breadcrump">
            <ul class="breadcrump--container text-center">
                <li  class="item" title="Retourner à cette étape">
                    <router-link to="/results">Recherche d'épreuve</router-link>
                </li>
                <li class="item" title="Retourner à cette étape">
                    <router-link to="/results">Résultats de la recherche</router-link>
                </li>
                <li  class="item" title="Retourner à cette étape">
                    <router-link to="/checkout">Épreuve choisie : "{{ race.nom }}"</router-link>
                </li>
                <li class="current-item item" title="Vous êtes ici">
                    <span>Modifier les résultats de l'épreuve</span>
                </li>
            </ul>
        </nav>
        <main class="main flex1 large-responsive-width column">
            <p class="text-info">Vous pouvez ici modifier les résultats ou supprimer des participants</p>
            <div>


                <section v-show='editList.length>0'>
                    <table class="table edition-mode need-mTB2">
                        <thead>
                            <tr>
                                <th>Licenciés</th>
                                <th>Rang</th>

                                <!-- ::: CHAMPS TEMPS : IMPLEMENTATION PLUS TARD ::: -->
                                <!-- <th>Temps</th> -->

                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(licensee, index) of editList" :key="licensee.id">
                                <td class="column pointer" :class="{'suppressed': licensee.willBeDeleted}">
                                    <span class="icon icon-pen-rounded"></span> {{licensee.nom}} {{licensee.prenom}}
                                    <span class="regular">{{licensee.noLicence}}</span>
                                </td>
                                <td v-show="!licensee.willBeDeleted">
                                    <div class="row row_center">
                                        <div class="input--container required">
                                            <input type="number"
                                                :placeholder="licensee.Rang ? licensee.Rang : '-'"
                                                step="1"
                                                min="0" 
                                                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null && validity.valid||(value='')"
                                                v-model="editList[index].Rang"
                                                @click="editRank(index)"
                                            >
                                        </div>
                                    </div>
                                </td>

                                <!-- ::: CHAMPS TEMPS : IMPLEMENTATION PLUS TARD ::: -->
                                <!-- <td v-show="!licensee.willBeDeleted">
                                    <span class="input--container">
                                        <input type="number"
                                            :placeholder="licensee.Temps ? licensee.Temps : '-'"
                                            step="1"
                                            min="0" 
                                            oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null && validity.valid||(value='')"
                                            v-model="editList[index].Temps"
                                            @click="editTime(index)"
                                        >
                                    </span>
                                </td> -->
                                
                                <td v-show="!licensee.willBeDeleted"><span class="delete-btn" @click="licensee.willBeDeleted = true" title="Supprimer ce licencié">X</span></td>
                                <td v-show="licensee.willBeDeleted" class='before-delete regular' colspan="2"><span @click="delete licensee.willBeDeleted">Annuler la suppression</span></td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>

            <p class="text" v-show="error">Veuillez remplir soit le temps soit le rang pour chaque licencié</p>
            
        </main>
         
        <footer class="pagination">
            <router-link to="/checkout" class="btn"><span class="zindex">Annuler</span></router-link>
            <button class="btn" @click="validateEdit()"><span class="zindex">Enregistrer</span></button>
        </footer>
       
    </div> 
</template>


<script>
import { mapGetters } from 'vuex'

import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'

export default {
    name: 'RaceSummaryAndModification',
    components: {
        HeaderComponent,
        PageTitle,
    },

    data(){
        return{
            error: false,
            editList: []
        }
    },

    computed: {
        ...mapGetters({
            race: 'changes/getTargetRace',
            results: 'changes/getRaceResults'
        }),

        toDayAndMonth(){
            return (value, formatDate)=>{
                return this.$dayjs(value).format(formatDate)
            }
        },
    },

    mounted(){
        this.editList = this.results.map((r) => r)
    },

    methods: {

        editRank(index){
            this.editList[index] = {
                ...this.editList[index],
                Temps: ''
            }
        },

        editTime(index){
            this.editList[index] = {
                ...this.editList[index],
                Rang: ''
            }
        },

        removeLicensee(licensee){
            licensee.willBeDeleted = true
        },

        validateEdit(){
            this.editList.forEach((el) => {
                if(el.willBeDeleted) {
                    this.editList = this.editList.filter((licensee) => {
                        return el.noLicence != licensee.noLicence ||
                        (el.nom != licensee.nom && el.prenom != licensee.prenom)
                    })
                }

                if(!el.Rang && !el.Temps){
                    this.error = true
                }
            })

            if(!this.error){
                this.$store.dispatch('changes/editRaceResults', this.editList)
                this.$router.push('/checkout')
            }
        },

    }
}
</script>

<style scoped>
.main {
  align-items: center;
}

.suppressed {
    text-decoration: line-through;
}

.delete-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  width: 2em;
  border-radius: 50%;
  font-family: 'Regular';
  background-color: firebrick;
  color: white;
  border: 1px solid transparent;
  margin-left: 1em;
  cursor: pointer;
  transition: .1s;
  box-shadow: 0px 4.29px 12.88px 0 rgba(0, 42, 103, 0.15);
}
.delete-btn:hover {
    background-color: white;
  color: firebrick;
  border: 1px solid firebrick;
}
.before-delete {
    text-align: center !important;

}
.before-delete span {
    cursor: pointer;
}

td {
    height: 2.7em;
}
</style>