<template>
    <div class="race-search page column">
        <LoadingSpinner v-show="loading" />
        <HeaderComponent />
        <PageTitle pageTitle="recherche d'une épreuve" />

        <OrangePopUp v-show="showOrangePopup" textPopup="Veuillez remplir au moins un champs de recherche" @closePopUp="showOrangePopup = false"/>

        <main class="main flex1 column mix-responsive-width">

            <section class="search-filters--container column row_center small-responsive-width">
                <p class="search-filters--btn bold relative" @click="visibleSearchFilters = !visibleSearchFilters">Filtres <span :class="{'rotate' : visibleSearchFilters }" class="icon icon-arrow-down"></span></p>
                <div class="search-filters--content" v-if="visibleSearchFilters">
                    <a href="http://www.ffvoile.fr/ffv/sportif/Calendrier/Calendrier.aspx" target="_blank" class="btn btn-short-medium border-mainColor bg-white-shadow lowercase radius25 need-mB2">
                        <span class="zindex">Calendrier Fédéral <span class="icon icon-external-link need-mL5"></span></span>
                    </a>
                    <router-link to="/history" class="btn btn-large bg-mainColor radius25">
                        <span class="zindex">Voir l'historique des épreuves traitées</span>
                    </router-link> 
                    <p class="text-center need-mTB2">OU</p>

                    <form class="column row_center">
                        <h3 class="title2 need-mB2">Recherche classique</h3>
                        <div class="row row_center need-mB">
                            <input type="checkbox" id="advanced-search" class="checkbox" v-model="advancedSearch">
                            <label class="btn--onoff" for="advanced-search"><span class="btn--onoff--text">Recherche avancée</span></label>
                        </div>
                        <p class="text-info">Merci de renseigner au moins un des champs</p>
                        <div class="input--container">
                            <span class="icon icon-diamond-rounded"></span>
                            <input 
                            type="text" 
                            placeholder="Nom de l'épreuve"
                            v-model="race.name"
                            >
                        </div>
                        <div class="input--container">
                            <span class="icon icon-location"></span>
                            <input 
                            type="text" 
                            placeholder="Lieu de l'épreuve"
                            v-model="race.location"
                            >
                        </div>
                        <div class="width100" v-show="advancedSearch">
                            <div class="input--container">
                                <span class="icon icon-calendar"></span>
                                <input
                                type="date" 
                                placeholder="Date de l'épreuve"
                                v-model="race.date"
                                >
                            </div>
                            <div class="input--container">
                                <span class="icon icon-diploma"></span>
                                <input 
                                type="text" 
                                placeholder="Code de l'épreuve"
                                v-model="race.code"
                                >
                            </div>
                            <div class="custom-select" :data-value="race.raceSupport" :data-list="raceSupportList">
                                <div class="selector relative" @click="toggleraceSupportSelect()">
                                    <div class="input--container  row row_center">
                                        <span class="icon icon-boat"></span>
                                        <span class="default" v-if="!race.raceSupport.option">Quel support</span>
                                        <span class="row" v-else>{{ race.raceSupport.option }}<span class="icon-cross-rounded need-mL5" @click="resetSelectedSupport()"></span></span>
                                        <span class="icon-switch icon-arrow-down" :class="{ 'anim-rotate' : visibleOptionsraceSupportSelect }"></span>
                                    </div>
                                    <div :class="['options-list', { visible : visibleOptionsraceSupportSelect }]">
                                        <ul>
                                            <li :class="{ current : item === race.raceSupport.option }" v-for="(item, id) in raceSupportList" @click="raceSupportSelect(item, id)" :key="id">{{ item }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-select" :data-value="race.raceLevel" :data-list="raceLevelList">
                                <div class="selector relative" @click="toggleraceLevelSelect()">
                                    <div class="input--container  row row_center">
                                        <span class="icon icon-level"></span>
                                        <span class="default" v-if="!race.raceLevel.option">Quel niveau</span>
                                        <span class="row" v-else>{{ race.raceLevel.option }}<span class="icon-cross-rounded need-mL5" @click="resetSelectedLevel()"></span></span>
                                        <span class="icon-switch icon-arrow-down" :class="{ 'anim-rotate' : visibleOptionsraceLevelSelect }"></span>
                                    </div>
                                    <div :class="['options-list', { visible : visibleOptionsraceLevelSelect }]">
                                        <ul>
                                            <li :class="{ current : item === race.raceLevel }" v-for="(item, id) in raceLevelList" @click="raceLevelSelect(item, id)" :key="id">{{ item }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-select" id="gradeselector" :data-value="race.raceGrade" :data-list="raceGradeList">
                                <div class="selector relative" @click="toggleraceGradeSelect()">
                                    <div class="input--container  row row_center">
                                        <span class="icon icon-grade"></span>
                                        <span class="default" v-if="!race.raceGrade.option">Quel grade</span>
                                        <span class="row" v-else>{{ race.raceGrade.option }}<span class="icon-cross-rounded need-mL5" @click="resetSelectedGrade()"></span></span>
                                        <span class="icon-switch icon-arrow-down" :class="{ 'anim-rotate' : visibleOptionsraceGradeSelect }"></span>
                                    </div>
                                    <div :class="['options-list', { visible : visibleOptionsraceGradeSelect }]">
                                        <ul>
                                            <li :class="{ current : item === race.raceGrade }" v-for="(item, id) in raceGradeList" @click="raceGradeSelect(item, id)" :key="id">{{ item }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button id="searchbtn" type="submit" class="btn btn-large bg-mainColor need-mT need-mB" @click.prevent="checkRaceSearchForm()">
                            <span class="zindex">Recherche</span>
                        </button>
                        
                        <p v-show="errorMessageRaceSearch">{{ errorMessageRaceSearch }}</p>
                    </form>

                </div>
            </section>
        </main>
    </div> 
</template>


<script>
import { mapGetters } from 'vuex'
import apiService from '@/services/api.service'

import LoadingSpinner from '../components/elements/LoadingSpinner.vue'
import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'
import OrangePopUp from '@/components/popups/OrangePopUp.vue'

export default {
    name: 'RaceSearch',

    components: {
        HeaderComponent,
        PageTitle,
        LoadingSpinner,
        OrangePopUp
    },

    data(){
        return{
            window : document.window,

            // Popup
            showOrangePopup: false,

            //Toggle on-off :
            advancedSearch : false,

            //Loading Spinner
            loading: false,

            //Filtres de recherche visibles:
            visibleSearchFilters : true,
            
            //Datas formulaire de recherche :
            race: {
                name: '',
                location : '',
                date : '',
                code : '',
                raceLevel : {
                    option : "",
                    id : ""
                },
                raceSupport: {
                    option : "",
                    id : ""
                },
                raceGrade: {
                    option : "",
                    id : ""
                },
            },

            //Pour le custom select raceLevel:
			raceLevelList: {},
            visibleOptionsraceLevelSelect: false,

            //Pour le custom select raceSupport:
			raceSupportList: {},
            visibleOptionsraceSupportSelect: false,

            //Pour le custom select raceGrade:
			raceGradeList: {},
            visibleOptionsraceGradeSelect: false,
    

            //Message d'erreur sur la recherche:
            errorMessageRaceSearch : "",

            //Résultats de la recherche d'épreuve :
            raceSearchResults : [],
        }
    },

    beforeMount(){
        this.getConfig()
    },

    computed:{
        ...mapGetters({
            hasEdit: 'changes/hasEdited',
            targetRace: 'changes/getTargetRace',
            prevSearch: 'navigation/history'
        }),

        currentUser(){
            return this.$store.state.auth.user.login
        },
    },

    methods: {

       /** 
        *  ------
        *    UX
        *  ------
        */

        // Récupérer la liste des supports et des niveaux d'épreuve pour la recherche
        async getConfig(){
            let response = await apiService.getSupportList()
            this.raceSupportList = response.data

            let response2 =  await apiService.getLevelList()
            this.raceLevelList = response2.data
            
            let response3  = await apiService.getGradeList()
            this.raceGradeList = response3.data
        },


    // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

       /** 
        *  ---------
        *  AFFICHAGE
        *  ---------
        */

        //Pour les custom selects
        toggleraceLevelSelect() {
            this.visibleOptionsraceLevelSelect = !this.visibleOptionsraceLevelSelect;
        },
        raceLevelSelect(option, id) {
            this.race.raceLevel.option = option;
            this.race.raceLevel.id = id;
        },
        toggleraceSupportSelect() {
            this.visibleOptionsraceSupportSelect = !this.visibleOptionsraceSupportSelect;
        },
        raceSupportSelect(option, id) {
            this.race.raceSupport.option = option;
            this.race.raceSupport.id = id;
        },
        toggleraceGradeSelect() {
            this.visibleOptionsraceGradeSelect = !this.visibleOptionsraceGradeSelect;
        },
        raceGradeSelect(option, id) {
            this.race.raceGrade.option = option;
            this.race.raceGrade.id = id;
        },

        // Reset des selects support, niveau ou grade selectionnés dans la recherche
        resetSelectedSupport(){
            this.race.raceSupport = {}
            this.visibleOptionsraceSupportSelect = !this.visibleOptionsraceSupportSelect
        },
        resetSelectedLevel(){
            this.race.raceLevel = {}
            this.visibleOptionsraceLevelSelect = !this.visibleOptionsraceLevelSelect
        },
        resetSelectedGrade(){
            this.race.raceGrade = {}
            this.visibleOptionsraceGradeSelect = !this.visibleOptionsraceGradeSelect
        },

        checkRaceSearchForm(){
            if (this.race.name || this.race.location || this.race.date || this.race.code || this.race.raceLevel.id || this.race.raceSupport.id || this.race.raceGrade.id){
              const dataToSend = {}

              if(this.race.name){
                  dataToSend.nomEpreuve = this.race.name
              }
              if(this.race.location){
                  dataToSend.lieuEpreuve = this.race.location
              }
              if(this.race.date){
                  dataToSend.dateEpreuve = this.race.date
              }
              if(this.race.code){
                  dataToSend.codeEpreuve = this.race.code
              }
              if(this.race.raceLevel.id){
                  dataToSend.niveauEpreuve = this.race.raceLevel.id
              }
              if(this.race.raceSupport.id){
                  dataToSend.supportEpreuve = this.race.raceSupport.id
              }
              if(this.race.raceGrade.id){
                  dataToSend.gradeEpreuve = this.race.raceGrade.id
              }

              this.getRaceSearchResult(dataToSend)
            }
            else {
                this.showOrangePopup = true
                // this.errorMessageRaceSearch = "Merci de renseigner au moins un des champs"
            }
        },

        // Récupérer la liste des supports et des niveaux d'épreuve pour la recherche


        //Recherche d'épreuve et récupérer les résultats
        async getRaceSearchResult(dataToSend){
            this.loading = true
            this.$store.dispatch("navigation/saveHistory", dataToSend);

            let response = await apiService.raceSearch(dataToSend);
            this.loading = false
            this.raceSearchResults = response.data
            console.log(response.data)
            if(this.raceSearchResults){
                if (this.raceSearchResults.length == 0){
                    this.errorMessageRaceSearch = "Nous n'avons trouvé aucune épreuve correspondant à votre recherche"
                } else {
                    this.errorMessageRaceSearch = ""
                    // this.visibleSearchFilters = false
                    // window.scrollTo({top: 0})
                    this.$router.push('/results')
                }
            } else {
                this.errorMessageRaceSearch = "Une erreur s'est produite, veuillez recommencer"
            }  
        },
    }
}
</script>