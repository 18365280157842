const history = localStorage.getItem('dev-ffv-history') ? JSON.parse(localStorage.getItem('dev-ffv-history')) : false

export const navigation = {
  namespaced: true,
  
  state: {
    version: 0,
    searchHistory: history,
  },

  getters: {
    getVersion: (state) => state.version,

    history: (state) => state.searchHistory,
  },

  actions: {
    setVersion({ commit }, version){
      commit('setVersion', version)
    },
    
    saveHistory({ commit }, values) {
      commit('saveHistory', values)
    },
    resetHistory({ commit }) {
      commit('resetHistory')
    }
  },
  
  mutations: {
    setVersion(state, version){
      state.version = version
    },
    
    saveHistory(state, values){
      state.searchHistory = values
      localStorage.setItem('dev-ffv-history', JSON.stringify(values))
    },
    resetHistory(state){
      state.searchHistory = null
    },
    
  }
}