<!-- LUCIEN : -->
<!-- / ! \ CETTE VUE N'EST PLUS UTILISÉE ET EST VOUÉE A DISPARAÎTRE / ! \ -->
<!-- / ! \ CETTE VUE N'EST PLUS UTILISÉE ET EST VOUÉE A DISPARAÎTRE / ! \ -->
<!-- / ! \ CETTE VUE N'EST PLUS UTILISÉE ET EST VOUÉE A DISPARAÎTRE / ! \ -->

<template>
    <div class="possible-actions-on-race page column">
        <HeaderComponent />
        <PageTitle pageTitle="que voulez-vous faire ?" />
        <nav class="breadcrump">
            <ul class="breadcrump--container text-center">
                <li  class="item" title="Retourner à cette étape">
                    <router-link to="/">Recherche d'épreuve</router-link>
                </li>
                <li class="current-item item" title="Vous êtes ici">
                    <span>Épreuve choisie : "{{ race.nom }}"</span>
                </li>
            </ul>
        </nav>
        <main class="main flex1 column small-responsive-width">
            <section class="race-card card need-mTB">
             <div class="row between row_center">
                        <h2 class="title2">{{ race.nom }}</h2>
                        <img 
                            v-show="race.resultatsTransmis" 
                            src="@/assets/trophy.svg" 
                            class="img"
                        />                    
                    </div>
                    </section>
            <section class="flex1 column row_center">


                <p class="need-mT5">Code de l'épreuve : {{ race.epreuveId }}</p>
                <p class="text-info need-mTB2">Choisissez parmi ces options :</p>
                <div class="flex1 column evenly">
                    <router-link
                        to="/race/infos"
                        class="btn btn-large btn--card bg-white_mainColorLighten"
                    >
                        <span class="zindex">Voir et ajouter<br/>des infos à l'épreuve</span>
                    </router-link>
                    <button 
                        @click="goTo('/race/licencee')" 
                        class="btn btn-large btn--card bg-white_mainColorLighten need-mT"
                        :disabled="!raceHasBasicInfos"
                    >
                        <span class="zindex">Rechercher et ajouter<br/>des licenciés</span>
                    </button>
                    <button 
                        @click="goTo('/race/edit')" 
                        class="btn btn-large btn--card bg-white_mainColorLighten need-mT"
                        :disabled="!raceHasBasicInfos"
                    >
                        <span class="zindex">Voir et modifier<br/>les résultats de l'épreuve</span>
                    </button>
                </div>
            </section>
        </main>
         <section class="pagination">
            <router-link class="btn btn-short-medium need-mT2" to="/"><span class="zindex">Retour</span></router-link>
            <button class="btn btn-short-medium bg-mainColorLighten need-mT2" @click="goToNewSearch()" ><span class="zindex">Nouvelle Recherche</span></button>
        </section>
    </div> 
</template>

<!-- LUCIEN : -->
<!-- / ! \ CETTE VUE N'EST PLUS UTILISÉE ET EST VOUÉE A DISPARAÎTRE / ! \ -->
<!-- / ! \ CETTE VUE N'EST PLUS UTILISÉE ET EST VOUÉE A DISPARAÎTRE / ! \ -->
<!-- / ! \ CETTE VUE N'EST PLUS UTILISÉE ET EST VOUÉE A DISPARAÎTRE / ! \ -->

<script>
import { mapGetters } from 'vuex'
import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'

export default {

    components: {
        HeaderComponent,
        PageTitle,
    },

    computed: {
        ...mapGetters({
            changes: 'changes/getChanges',
            race: 'changes/getTargetRace',
            hasEdited: 'changes/hasEdited'
        }),

        raceHasBasicInfos(){
            return (this.race.nbCoureurs && this.race.nbManches) || (this.changes.raceInfos)
        }
    },
    mounted() {
       if (!this.race) this.$router.push('/')  
    },
    methods: {
        goTo(path){
            this.$router.push(path)
        },
         goToNewSearch() {
            this.$store.dispatch('navigation/resetHistory')
            this.$router.push('/')
            }
    }
}
</script>

<!-- LUCIEN : -->
<!-- / ! \ CETTE VUE N'EST PLUS UTILISÉE ET EST VOUÉE A DISPARAÎTRE / ! \ -->
<!-- / ! \ CETTE VUE N'EST PLUS UTILISÉE ET EST VOUÉE A DISPARAÎTRE / ! \ -->
<!-- / ! \ CETTE VUE N'EST PLUS UTILISÉE ET EST VOUÉE A DISPARAÎTRE / ! \ -->