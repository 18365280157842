import axiosInstance from "./api";
import storageService from "./storage.service";
import router from '@/router'

const setup = (store) => {
    axiosInstance.interceptors.request.use(
    (config) => {
        const data = storageService.get();
      if (data && data.token) {
        config.headers["Authorization"] = 'Bearer ' + data.token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) =>  res,
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/account/login" && err.response) {
          store.dispatch('auth/logout');
          router.push('/Login');
      }
    }
  );
};
export default setup;