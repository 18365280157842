<template>
  <header class="header-component">
    <div class="header-component--content bg-lightGrey">
      <img class="logo" src="@/assets/logo.png" alt="logo de la Fédération Française de Voile"/>
      <div class="row row_center">
        <div class="column align-end need-mR">
          <p class="name">{{currentUser.prenom}} {{currentUser.nom}}</p>
          <p class="text-red">{{currentUser.noLicence}}</p>
        </div>
        <div>
            <!--input type="checkbox" id="btn-menu" class="checkbox" :checked="visibleMenu = true"-->
            <div class="hamburger column" @click="showMenu = !showMenu">
              <span class="warning-icon icon-warning" v-if="hasUnsentChanges"></span>
              <span class="hamburger--item"></span>
              <span class="hamburger--item--transparent"></span>
              <span class="hamburger--item"></span>
              <span class="hamburger--item--transparent"></span>
              <span class="hamburger--item"></span>
            </div>
        </div>
      </div>
    </div>
    <section class="popup--container" v-if="isExpired">
        <div class="bg-blur"></div>
        <div class="popup bg-red">
            <span class="icon icon-exclamation-rounded"></span>
            <p class="need-mTB2 text-center">Session Expirée, vous devez vous déconnecter</p>
            <div class="row row_center">
                <router-link to="/logout" class="popup-btn">D'accord</router-link>
            </div>
        </div>
    </section>
    <section class="menu--container column bg-mainColorLighten" @click="showMenu = !showMenu" :class="{ openingTransition: showMenu }">
        <div class="menu-main-content flex1 column row_center bg-lightGrey">
          <div>
            <img class="logo" src="@/assets/logo.png" alt="logo de la Fédération Française de Voile"/>
            <div class="separation--short"></div>
            <p class="menu-title">Menu</p>
          </div>
          <div class="btn-menu--container column center flex1">
            <router-link to="/checkout" class="btn-menu btn-yellow" v-show="hasUnsentChanges">
              <span class="icon icon-warning"></span>
              Saisie non envoyée
            </router-link>
            <button @click="goToNewSearch()" class="btn-menu">
              <span class="icon icon-search-rounded"></span>
              Rechercher une épreuve
            </button>
            <router-link to="/history" class="btn-menu">
              <span class="icon icon-page-rounded"></span>
              Historique des épreuves traitées
            </router-link>
            <router-link to="/profile" class="btn-menu">
              <span class="icon icon-user-rounded"></span>
              Mon profil
            </router-link>
            <router-link to="/links" class="btn-menu">
              <span class="icon icon-page-writting-rounded"></span>
              Liens utiles
            </router-link>
              <router-link to="/feedbacks" class="btn-menu">
              <span class="icon icon-page-writting-rounded"></span>
              Un problème ?
            </router-link>
          </div>
        </div>
        <div class="close-menu--container column row_center">
          <button @click="showMenu = !showMenu"><span @click="showMenu = !showMenu" class="icon icon-cross-rounded"></span></button>
        </div>
              <span class="discreet-text" v-show="showMenu">{{version}}</span>

      </section>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderComponent',

  data(){
    return{
      showMenu: false,
    }
  },

  computed:{
    currentUser(){
      return this.$store.state.auth.user.login
    },

    ...mapGetters({
      hasUnsentChanges: 'changes/hasEdited',
      version: 'navigation/getVersion',
      isExpired: 'auth/sessionExpired'
    })
  },
  methods: {
    goToNewSearch() {
      this.$store.dispatch('navigation/resetHistory')
      this.$router.push('/')
    }
  }
}
</script>

