
const resultsInProgress = JSON.parse(localStorage.getItem('dev-ffv-modifs'));

const initialState = {
  changes: {
    targetRace: false,
    raceInfos: false,
    raceResults: false,
    hasEdited: false,
  },
}

export const changes = {
  namespaced: true,

  state: resultsInProgress ? {changes: resultsInProgress} : initialState,

  getters: {
    hasEdited:(state) => state.changes.hasEdited,
    getChanges: (state) => state.changes,
    getTargetRace: (state) =>  state.changes.targetRace,
    getRaceResults: (state) =>  {
      const results = state.changes.raceResults
      if(results && results.length) return results.sort((a, b) => a.Rang - b.Rang)
      else return false
    },
    hasUnsentChanges : (state) =>  state.hasEdited
  },

      // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

  actions: {

    /**
     *  -------------
     *  GET BASE DATA
     *  -------------
     */

    // Race data
    setRace({ commit }, race){
      commit('setRace', race)
    },

    // Results data
    setRaceResultsFromApi({ commit }, results){
      commit('setRaceResultsFromApi', results)
    },


    /**
     *  --------------
     *  EDIT RACE DATA
     *  --------------
     */

    // Edit race infos
    updateRaceInfos({commit}, payload){
      commit('updateRaceInfos', payload)
      commit('hasEdited')
    },

    // Edit race results
    editRaceResults({commit}, data){
      commit('editRaceResults', data)
      commit('hasEdited')
    },

    // Edit licensees
    addLicensees({commit}, data){
      commit('addLicensees', data)
      commit('hasEdited')
    },
    removeLicensee({ commit }, licensee){
      commit('removeLicensee', licensee)
      commit('hasEdited')
    },



    /**
     *  ----------
     *  RESET DATA
     *  ----------
     */

    resetEditedChanges({ commit }){
      commit('resetEditedChanges')
    },

    reset({commit}){
      commit('reset')
    },

    resetAll({commit}){
      commit('resetAll')
    }
  },

      // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

  mutations: {

    // Track user modifications
    hasEdited(state){
      state.changes.hasEdited = true
      localStorage.setItem('dev-ffv-modifs', JSON.stringify(state.changes))
    },
    
    /**
     *  -------------
     *  GET BASE DATA
     *  -------------
     */
    
    setRace(state, race){
      state.changes.targetRace = race
      localStorage.setItem('dev-ffv-modifs', JSON.stringify(state.changes))
    },
    
    setRaceResultsFromApi(state, response){
      const results = response.data
      .map((r) => {
        const newR = {
          ...r,
          Rang: r.place,
          Temps: r.temps
        }
        delete newR.place
        delete newR.temps
        return newR
      })
      
      state.changes.raceResults = results
      localStorage.setItem('dev-ffv-modifs', JSON.stringify(state.changes))
    },
    
    
    
    /**
     *  --------------
     *  EDIT RACE DATA
     *  --------------
     */
    
    // Edit race infos
    updateRaceInfos(state, infos){
      state.changes.raceInfos = {
        NbClasses: infos.runnersNumber,
        NbManches_valides: infos.racesNumber,
        NbNations: infos.nationsNumber,
        VerifLink: infos.verifLink
      }
      localStorage.setItem('dev-ffv-modifs', JSON.stringify(state.changes))
    },
    
    
    
    // Edit race results
    editRaceResults(state, data){
      state.changes.raceResults = data
      localStorage.setItem('dev-ffv-modifs', JSON.stringify(state.changes))
    },
    
    
    
    // Edit licensees
    addLicensees(state, licensees){
      licensees.forEach((l) => {
        state.changes.raceResults.push(l)
      })
      localStorage.setItem('dev-ffv-modifs', JSON.stringify(state.changes))
    },
    
    removeLicensee(state, licensee){
      state.changes.raceResults = state.changes.raceResults.filter((el) => {
        return el.noLicence != licensee.noLicence || (el.nom != licensee.nom && el.prenom != licensee.prenom)
      })
      localStorage.setItem('dev-ffv-modifs', JSON.stringify(state.changes))
    },
    
    
    
    /**
     *  ----------
     *  RESET DATA
     *  ----------
     */
    
    resetEditedChanges(state){
      state.changes.raceInfos = false,
      state.changes.raceResults = false,
      state.changes.hasEdited = false
      localStorage.setItem('dev-ffv-modifs', JSON.stringify(state.changes));
    },
    
    reset(state){
      let race = state.changes.targetRace
      state.changes = {
        targetRace: race,
        raceInfos: false,
        raceResults: false,
        hasEdited: false
      }
      localStorage.removeItem('dev-ffv-modifs');
    },
    
    resetAll(state){
      state.changes = {
        targetRace: false,
        raceInfos: false,
        raceResults: false,
        hasEdited: false,
      }
      localStorage.removeItem('dev-ffv-modifs');
    }
  }
}