<template>
    <div class="race-results page column">
      
        <HeaderComponent />

        <PageTitle pageTitle="Votre résumé de saisie" />

        <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                    <!-- POPUPS -->

        <aside class="popup--container" v-show="showCheckoutPopup" >
            <div class="bg-blur"></div>
            <div class="popup bg-mainColorLighten">
                <span class="icon icon-exclamation-rounded"></span>
                <p class="need-mTB2 text-center">Merci, vos données ont bien été envoyées !</p>
                <div class="btn-line column row_center wrap">
                    <button class="popup-btn" @click="closeValidationPopup()">Revenir sur la page</button>
                    <button class="popup-btn" @click="oldSearch()">Retour à la recherche</button>
                    <button class="popup-btn" @click="newSearch(true)">Lancer une nouvelle recherche</button>
                </div>
            </div>
        </aside>

        <RedPopUp v-show="showRedPopup" textPopup="Quelque chose s'est mal passé, veuillez réessayer" @closePopUp="showRedPopup = false" />

        <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                    <!-- FIL D'ARIANNE -->
                                    
        <nav class="breadcrump">
            <ul class="breadcrump--container text-center">
                <li  class="item" title="Retourner à cette étape">
                    <router-link to="/">Recherche d'épreuve</router-link>
                </li>
                <li class="item" title="Retourner à cette étape">
                    <router-link to="/results">Résultats de la recherche</router-link>
                </li>
                <li  class="item" title="Retourner à cette étape">
                    <router-link to="/checkout">Épreuve choisie : "{{ race.nom }}"</router-link>
                </li>
                <li class="current-item item" title="Vous êtes ici">
                    <span>Résumé des informations de la course</span>
                </li>
            </ul>
        </nav>


        <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                    <!-- RÉSUMÉ INFOS COURSE -->
        
        <main class="main flex1 column small-responsive-width">
            <p class="text-info">Voici les informations relatives à la course choisie.</p>

            <loading-spinner v-show="loading" />
            
            <!-- INFOS COURSE -->
            <section class="race-card card need-mTB">
                <div class="row between align-start">
                    <h2 class="title2">{{ race.nom }}</h2>    
                    <img title="Résultats disponibles" src="@/assets/trophy.svg" class="img need-mL5" v-show="race.resultatsTransmis" />            
                </div>
                <div class="bg-lightGrey inside-card need-mTB">
                    <p v-show="race.organisateur">
                        <span class="icon icon-star"></span>
                        Organisé par : {{ race.organisateur }}
                    </p>
                    <p v-show="race.lieu">
                        <span class="icon icon-location"></span>
                        Lieu : {{ race.lieu }}
                    </p>
                    <p v-show="race.hnLieu">
                        <span class="icon icon-diamond-rounded"></span>
                        {{ race.hnLieu }}
                    </p>
                    <p v-show="race.dateDebut && race.dateFin">
                        <span class="icon icon-calendar"></span>
                        Début : {{ toDayAndMonth(race.dateDebut,'DD/MM/YYYY') }} • Fin : {{ toDayAndMonth(race.dateFin,'DD/MM/YYYY') }}
                    </p>
                    <p>
                        <span class="icon icon-diploma"></span>
                        Code de l'épreuve : {{ race.epreuveId }}
                    </p>
                    <p v-show="race.hnNiveau">
                        <span class="icon icon-level"></span>
                        Niveau : {{ race.hnNiveau }}
                    </p>
                    <p v-show="race.support">
                        <span class="icon icon-boat"></span>
                        {{ race.support }}
                    </p>
                    <p class="bold main-color-lighten">
                        <span class="icon icon-user"></span>
                        Nombre de coureurs :<span class="need-mL5" v-if="changes.raceInfos.NbClasses">{{ changes.raceInfos.NbClasses }}</span><span class="need-mL5" v-else>{{ race.nbCoureurs || "A renseigner" }}</span>
                    </p>
                    <p class="bold main-color-lighten">
                        <span class="icon icon-itinerary"></span>
                        Nombre de courses :<span class="need-mL5" v-if="changes.raceInfos.NbManches_valides">{{ changes.raceInfos.NbManches_valides }}</span><span class="need-mL5" v-else>{{ race.nbManches || "A renseigner" }}</span>
                    </p>
                    <p>
                        <span class="icon icon-flag"></span>
                        Nombre de nations : <span class="need-mL5" v-if="changes.raceInfos.NbNations">{{ changes.raceInfos.NbNations }}</span><span class="need-mL5" v-else>{{ race.nbNations || "A renseigner" }}</span>
                    </p>
                    <p class="own-style">
                        <span class="icon icon-link"></span>
                        Lien de vérif : 
                        <a :href="changes.raceInfos.VerifLink" target="_blank" class="need-mL5 link--hover-underline" v-if="changes.raceInfos.VerifLink">{{changes.raceInfos.VerifLink }}</a>
                        <a :href="race.urlVerif" target="_blank" class="need-mL5 link--hover-underline" v-else-if="race.urlVerif">{{ race.urlVerif }}</a>
                        <span class="need-mL5" v-else>A renseigner</span>
                    </p>
                    <p v-show=" race.urlEpreuve" class="own-style">
                        <span class="icon icon-web"></span>
                        Site : <a :href=" race.urlEpreuve" target="_blank" class="need-mL5 link--hover-underline">{{ race.urlEpreuve }}</a>
                    </p>
                </div>
                <router-link v-if="!race.lock" class="btn btn-short bg-mainColorLighten" to="/race/infos"><span class="zindex">Modifier les informations</span></router-link>
            </section>

            <!-- INFOS PARTICIPANTS -->
            <section class="race-card card need-mTB" v-if="raceResults.length>0">
                <table class="table edition-mode need-mB2">
                    <thead>
                        <tr>
                            <th>Licenciés</th>
                            <th class="pointer" @click="toggleInvertRank()">Rang</th>
                            <th>Temps</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="licensee of results" :key="licensee.id">
                            <td class="column pointer">
                                <span class="icon icon-pen-rounded"></span> {{licensee.nom}} {{licensee.prenom}}
                                <span class="regular">{{licensee.noLicence}}</span>
                            </td>
                            <td>{{licensee.Rang}}</td>
                            <td>{{licensee.Temps}}</td>
                        </tr>
                    </tbody>
                </table>
                <router-link class="btn btn-short bg-mainColorLighten" to="/race/edit"><span class="zindex">Modifier les résultats</span></router-link>
            </section>

            <router-link v-if="hasBasicResults && !race.lock" class="btn btn-short bg-mainColorLighten" to="/race/licencee"><span class="zindex">Ajouter des participants</span></router-link>
            <p class="orange-color text-center" v-if="!hasBasicResults && !race.lock">Vous devez au moins remplir le nombre de coureurs et de manche de cette épreuve avant de pouvoir y ajouter des participants ou la modifier.</p>
        </main>

        <!-- ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~ -->
                                    <!-- PAGINATION -->

        <footer class="pagination">
            <router-link class="btn btn-short-medium need-mT2" to="/results"><span class="zindex">Retour</span></router-link>
            <button v-if="!race.lock" class="btn btn-short-medium bg-mainColorLighten need-mT2" @click="sendData" ><span class="zindex">Envoyer</span></button>
        </footer>
        
    </div> 
</template>


<script>
import { mapGetters } from 'vuex'

import apiService from '@/services/api.service'
import Draft from '@/models/Draft'

import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import PageTitle from '@/components/elements/PageTitle.vue'
import RedPopUp from '@/components/popups/RedPopUp.vue'
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue'

export default {
    name: 'CheckingInfos',

    components: {
        HeaderComponent,
        PageTitle,
        RedPopUp,
        LoadingSpinner
    },

    data(){
        return{
            loading: false,
            showCheckoutPopup: false,
            showRedPopup: false,
            invertRank: false,
            results: [],
        }
    },

    mounted(){
        if(this.raceResults){
            this.results = [...this.raceResults]
        }
    },

    computed:{
        ...mapGetters({
            changes: 'changes/getChanges',
            race: 'changes/getTargetRace',
            user: 'auth/currentUser',
            raceResults: 'changes/getRaceResults',
            history: 'navigation/history',
        }),

        toDayAndMonth(){
            return (value, formatDate)=>{
                return this.$dayjs(value).format(formatDate)
            }
        },

        hasBasicResults(){
            return ((this.race.nbCoureurs && this.race.nbManches) || (this.changes.raceInfos.NbClasses && this.changes.raceInfos.NbManches_valides)) ?? false
        },
    },

    methods: {
        toggleInvertRank(){
            this.results = this.results.reverse()
        },

        async sendData(){
            this.loading = true
            const draft = new Draft(this.user.login, this.changes).create()

            try {
                console.info(draft)
                await apiService.sendData(draft)
                this.loading = false
                this.showCheckoutPopup = true
            }
            catch {
                this.showRedPopup = true
            }
        },

        // CONFIRMATION POPUP
        async closeValidationPopup(){
            this.loading = true
            // récupère les données de la course à la BDD après envoi des donnéees
            try {
                this.$store.dispatch('changes/reset')

                // race data
                const raceResponse = await apiService.raceSearch(this.history)
                const targetRace = raceResponse.data.find((r) => r.epreuveId === this.race.epreuveId)
                this.$store.dispatch('changes/setRace', targetRace)

                // race results data
                const resultsResponse = await apiService.getRaceResults(targetRace.epreuveId)
                this.$store.dispatch('changes/setRaceResultsFromApi', resultsResponse)
            }
            catch {
                this.showRedPopup = true
            }
            this.showCheckoutPopup = false
            this.loading = false
        },

        oldSearch(){
            this.$store.dispatch('changes/resetAll')
            this.$router.push('/results')
        },

        newSearch(){
            this.$store.dispatch('navigation/resetHistory')
            this.$store.dispatch('changes/resetAll')
            this.$router.push('/')
        },
    }
}
</script>