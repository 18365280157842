import { createRouter, createWebHistory } from 'vue-router'

// User
import LoginView from '../views/user/LoginView.vue'
import LogoutView from '../views/user/LogoutView.vue'
import LoggedUserProfile from '../views/user/LoggedUserProfile.vue'
import UsefulLinks from '../views/user/UsefulLinks.vue'
import ModifiedRaceHistory from '../views/user/ModifiedRaceHistory.vue'
import FeedbacksView from  '../views/user/FeedbacksView.vue'
// Nav
import HomeView from '../views/HomeView.vue'
import RaceResults from '../views/RaceResultsView.vue'
import ModificationTree from '../views/ModificationTreeView.vue'

// Modifications
import EditInfos from '../views/modifications/EditRaceInfosView.vue'
import AddLicencee from '../views/modifications/AddLicenceeView.vue'
import EditResults from '../views/modifications/EditResultsView.vue'
import Checkout from '../views/modifications/CheckoutView.vue'

// import LicenseeModification from '../views/modifications/LicenseeModification.vue'


const routes = [

  /**
   *  ------
   *   HOME
   *  ------
   */

  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    meta: { requiresAuth: true}
  },

  {
    path: '/results',
    name: 'RaceResults',
    component: RaceResults,
    meta: { requiresAuth: true}
  },

  {
    path: '/race',
    name: 'ModificationTree',
    component: ModificationTree,
    meta: { requiresAuth: true},
  },

  // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

  /**
   *  -----------
   *   USER MENU
   *  -----------
   */

  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutView,
    //meta: { requiresAuth: true}
  },
  {
    path: '/profile',
    name: 'LoggedUserProfile',
    component: LoggedUserProfile,
    meta: { requiresAuth: true}
  },
  {
    path: '/links',
    name: 'UsefulLinks',
    component: UsefulLinks,
    meta: { requiresAuth: true}
  },
  {
    path: '/feedbacks',
    name: 'FeedbacksView',
    component: FeedbacksView,
    meta: { requiresAuth: true}
  },
  
  {
    path: '/history',
    name: 'ModifiedRaceHistory',
    component: ModifiedRaceHistory,
    meta: { requiresAuth: true}
  },

  // ~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~.~. \\

  /**
   *  ---------------
   *   MODIFICATIONS
   *  ---------------
   */

  {
    path: '/race/infos',
    name: 'RaceInfos',
    component: EditInfos,
    meta: { requiresAuth: true}
  },
  {
    path: '/race/licencee',
    name: 'AddLicencee',
    component: AddLicencee,
    meta: { requiresAuth: true}
  },
  {
    path: '/race/edit',
    name: 'EditResults',
    component: EditResults,
    meta: { requiresAuth: true}
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: { requiresAuth: true}
  },

  /*{
    path: '/LicenseeSearch/:raceId',
    name: 'LicenseeSearch',
    component: LicenseeSearch,
    props: true,
    meta: { requiresAuth: true}
  },*/
/*   {
    path: '/LicenseeModification/:licenseeId',
    name: 'LicenseeModification',
    component: LicenseeModification,
    props: true,
    meta: { requiresAuth: true}
  }, */
]


const router = createRouter({
    history: createWebHistory(),
  routes
})

export default router
   